import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment'
import _ from 'lodash';
const ChoosePeriod = (props) => {
    const [disabled, setDisabled] = React.useState(true)
    const [from, setFrom] = React.useState(new Date())
    const [to, setTo] = React.useState(new Date())
    const modifiers = { start: from, end: to }
    const [startDate, setStartDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
    const [endDate, setEndDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'))
    const [checkedValue, setCheckedValue] = React.useState(props.checkedValue ? props.checkedValue : "LifeTime")
    
    const [InstalledDate] = React.useState(props.InstaltedDate)
    const periodLable = [
        { "value": "Daily", "label": "Today", "disabled": false },
        { "value": "Weekly", "label": "This Week", "disabled": false },
        { "value": "Monthly", "label": "This Month", "disabled": false },
        { "value": "Yearly", "label": "This Year", "disabled": false },
        { "value": "LifeTime", "label": "Lifetime", "disabled": false },
        { "value": "Custom", "label": "Custom", "disabled": false }
    ]
    if (props.disabled) {
        _.map(periodLable, ele => {
            _.map(props.disabled, disabledValue => {
                if (ele.value === disabledValue) {
                    ele.disabled = true
                }
            })
        })
    }
    const onRadioButtonClick = (ele) => {
        let todayDate = new Date()
        setCheckedValue(ele.value)
        switch (ele.value) {
            case "Daily":
                setStartDate(moment(todayDate).format('YYYY-MM-DD'))
                setEndDate(moment(todayDate).format('YYYY-MM-DD'))
                setDisabled(true)
                break;
            case "Weekly":
                let firstday = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay()))
                let lastday = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay() + 6))
                setStartDate(moment(firstday).format('YYYY-MM-DD'))
                setEndDate(moment(lastday).format('YYYY-MM-DD'))
                setDisabled(true)
                break;
            case "Monthly":
                let monthlyStartDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
                let monthlyEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0)
                setStartDate(moment(monthlyStartDate).format('YYYY-MM-DD'))
                setEndDate(moment(monthlyEndDate).format('YYYY-MM-DD'))
                setDisabled(true)
                break;
            case "Yearly":
                let yearStartDate = new Date(new Date().getFullYear(), 0, 1)
                let yearEndDate = new Date(new Date().getFullYear() + 1, 0, 0)
                setStartDate(moment(yearStartDate).format('YYYY-MM-DD'))
                setEndDate(moment(yearEndDate).format('YYYY-MM-DD'))
                setDisabled(true)
                break;
            case "LifeTime":
                let lifeTimeStartDate = props.InstaltedDate;
                let lifeTimeEndDate = new Date()
                setStartDate(moment(lifeTimeStartDate).format('YYYY-MM-DD'))
                setEndDate(moment(lifeTimeEndDate).format('YYYY-MM-DD'))
                setDisabled(true)
                break;
            case "Custom":
                setStartDate(moment(startDate).format('YYYY-MM-DD'))
                setEndDate(moment(endDate).format('YYYY-MM-DD'))
                setDisabled(false)
                break;
            default:
                setDisabled(true)
        }
        
        // props.onChange(startDate, endDate, checkedValue)
    }

    const handleFromChange = (e) => {
        
        
        setFrom(e)
        setStartDate(moment(e).format('YYYY-MM-DD'))
        // props.onChange(startDate, endDate, checkedValue)
    }
    const handleToChange = (e) => {
        
        setTo(e)
        setEndDate(moment(e).format('YYYY-MM-DD'))
        // props.onChange(startDate, endDate, checkedValue)
    }

    const getFromDayPicker = () => {
        
        return {
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 1,
        }
    }
    const getToDayPicker = () => {
        let fromDate= new Date(startDate)
        return {
            disabledDays: { before: fromDate, after: new Date() },
            modifiers,
            month: fromDate,
            fromMonth: fromDate,
            numberOfMonths: 1,
        }
    }
    React.useEffect(() => {
        if(checkedValue==="Monthly"){
            let todayDate = new Date()
            let monthlyStartDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1)
            let monthlyEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0)
            setStartDate(moment(monthlyStartDate).format('YYYY-MM-DD'))
            setEndDate(moment(monthlyEndDate).format('YYYY-MM-DD'))
        }
        
        props.onChange(startDate, endDate, checkedValue)
    }, [startDate, endDate, checkedValue])

    React.useEffect(() => {
        if(checkedValue==="LifeTime"){
            let lifeTimeStartDate = props.InstaltedDate;
            setStartDate(moment(lifeTimeStartDate).format('YYYY-MM-DD'))    
        }
    }, [props.InstaltedDate])

    const renderRadioOption = periodLable.map(ele => {
        return <div key={Math.random()} className="font-normal mt-2"><input type="radio" disabled={ele.disabled} checked={checkedValue === ele.value} value={ele.value} onClick={() => onRadioButtonClick(ele)} /> {ele.label}</div>
    })
    return <div>
        <div className="font-size-22">Choose period</div>
        <div>
            {renderRadioOption}
        </div>
        <div className="font-size-12 mt-2 textColor">
            From&nbsp; <DayPickerInput type="date" value={startDate} inputProps={{ disabled: disabled }} placeholder="From" format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate} dayPickerProps={getFromDayPicker()}
                class="test"
                onDayChange={(e) => handleFromChange(e)}
            />{' '}
            {' '}
            <span className="">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To&nbsp; <DayPickerInput type="date" value={endDate} inputProps={{ disabled: disabled }} placeholder="#61447 To" format="YYYY-MM-DD" formatDate={formatDate} parseDate={parseDate}
                    dayPickerProps={getToDayPicker()}
                    onDayChange={(e) => handleToChange(e)}
                />
            </span>&nbsp;
        </div>
    </div>
}

export default ChoosePeriod