import React from 'react'

import Auth from '../api/ApiSession';
const useForm = (validate) => {
    const authCall = new Auth();
    const [responseData,setResponseData]=React.useState(null)
    const [values, setValues] = React.useState({
        "Prefix": "Mr",
        "FirstName": "",
        "LastName": "",
        "EmailId": "",
        "Password": "",
        "Address": "",
        "City": "",
        "State": "",
        "Country": "",
        "Timezone": "",
        "ContactNo": "",
        "Zipcode": "",
        // "CompanyName": "",
        // "CompanyAddress": "",
        // "Industry": "",
        "ConfirmPassword":"",
        "UserType": "Agro",
        "terms":false
    })
    const [errors, setErrors] = React.useState({})
    const handleChange = (e) => {
        let { name, value } = e.target
        values[name] = value;
        setErrors(validate(values,name))
        setValues(values);
    }
    const handletermsChk = (e) => {
        let name = e.target.name
        let value = e.target.checked
        values[name] = value;
        setErrors(validate(values,name))
        setValues(values);
    }
    const handleSubmit = (evt) => {
        evt.preventDefault();
        let postValues = JSON.parse(JSON.stringify(values))
        delete postValues.ConfirmPassword
        delete postValues.terms

        console.log(postValues)
        postForm(postValues)
    }
    const postForm = () => {
        authCall.postUserFormDetails(values).then(response => {
            console.log(response)
            setResponseData(response)
        }).catch(error => {
            console.log(error);
        });
    }
    const updateMapLatLong = (latLong, mapKey) => {
        updateFormInputs(latLong[0], latLong[1], mapKey)
    }
   
    const updateFormInputs = (long, lat, mapKey) => {
        let oldform = JSON.parse(JSON.stringify(values))
        fetch("https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=" + mapKey + "&query=" + lat + "," + long, {
            method: 'GET', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response
        }).then(res => res.json())
            .then(json => {

                oldform['City'] = (json.addresses[0].address.countrySecondarySubdivision === undefined) ? "" : json.addresses[0].address.countrySecondarySubdivision
                oldform['Country'] = (json.addresses[0].address.country === undefined) ? "" : json.addresses[0].address.country
                oldform['Zipcode'] = (json.addresses[0].address.postalCode === undefined) ? "" : json.addresses[0].address.postalCode
                oldform['State'] = (json.addresses[0].address.countrySubdivision === undefined) ? "" : json.addresses[0].address.countrySubdivision

            })

        fetch("https://atlas.microsoft.com/timezone/byCoordinates/json?api-version=1.0&subscription-key=" + mapKey + "&query=" + lat + "," + long, {
            method: 'GET', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            return response
        }).then(res => res.json())
            .then(json => {
                oldform['Timezone'] = (json.TimeZones[0].Names.Standard === undefined) ? "" : json.TimeZones[0].Names.Standard
                setValues(oldform)
            })

    }

    return { values,handleChange,handleSubmit,updateMapLatLong,errors,handletermsChk,responseData }
}

export default useForm