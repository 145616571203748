import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Auth from '../api/ApiSession'
import _ from 'lodash';
import "react-datetime/css/react-datetime.css";
import "../font/css/styles.css"
import Datetime from "react-datetime";
import moment from 'moment'
const authCall = new Auth()
const AdminPage = () => {
    const [btnDisabled, setBtnDisabled] = React.useState(true)
    const [dataToPost, setDataToPost] = React.useState([])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const [versionInfo, setVersionInfo] = React.useState({})
    const [deviceStatus, setDeviceStatus] = React.useState([])
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [initialValue, setInitialValue] = React.useState(new Date())
    const [scheduleSelecteion, setScheduleSelecteion] = React.useState('0')
    const [dataToUploadBin, setDataToUploadBin] = React.useState({
        "FirmwareFile": "",
        "Type": sessionStorage.getItem("customerType"),
        "Version": "",
        "FileName": ""
    })
    const [inputProps, setInputProps] = React.useState({
        placeholder: "",
        disabled: true
    })
    let tabBarItem = [
        "Upload",
        "Upgrade Firmware"]
    const schedule = () => {
        var data={"userid":sessionStorage.getItem("userId"),"data":dataToPost}
        authCall.postUpdateFirmwareSetting(data).then(response => {
        }).catch(error => {
            console.log(error);
        });
        document.getElementById('admin-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Firmware update scheduled" })
    }
    const forDataToPost = (selection, version) => {
        let objToPost = []
        if (selection === "1") {
            setInputProps({
                placeholder: "",
                disabled: false,
            })
            _.map(deviceStatus, (ele) => {
                objToPost.push({
                    "deviceId": ele.deviceid,
                    "firmwareUpdateSettings": {
                        "newversion": version,
                        "schedule": {
                            "immediate": false,
                            "time": {
                                "numberOfTicks": moment(initialValue).valueOf() / 1000
                            }
                        }
                    }
                })
            })
        } else {
            setInputProps({
                placeholder: "",
                disabled: true,
                value: ""
            })
            _.map(deviceStatus, (ele) => {
                objToPost.push({
                    "deviceId": ele.deviceid,
                    "firmwareUpdateSettings": {
                        "newversion": version,
                        "schedule": {
                            "immediate": true,
                            "time": {
                                "numberOfTicks": null
                            }
                        }
                    }
                })
            })
        }
        setDataToPost(objToPost)
    }
    const onScheduleChange = (selection) => {
        setBtnDisabled(false)
        setScheduleSelecteion(selection)
        forDataToPost(selection, versionInfo.version)

    }
    const selectItem = (index) => {
        setSelectedIndex(index)
    }
    const list = _.map(tabBarItem, (ele, index) => {
        return <a onClick={() => selectItem(index)} className={index === selectedIndex ? "active" : null}>{ele}</a>
    })
    const fetchVersionDetails = () => {
        authCall.getFirmwareVersion(sessionStorage.getItem("customerType")).then(entries => {
            if(entries.length===0){
                entries.push({"version":"NA"})
            }
            setVersionInfo(entries[0])
        }).catch(error => {
            console.log(error);
        });
    }
    const fetchDeviceStatusDetails = () => {
        let obj = {
            "userId": sessionStorage.getItem("userId"),
            "siteName": sessionStorage.getItem("siteName"),
            "customerId": sessionStorage.getItem("customerId"),
            "customerType": sessionStorage.getItem("customerType")
        }
        authCall.getCustomerDeviceList(obj).then(entries => {
            setDeviceStatus(entries)
        }).catch(error => {
            console.log(error);
        });
    }
    const tableGroup = _.map(deviceStatus, (ele, index) => {
        return <se-table-group selectable="true">
            <se-table-item width="53px"><se-checkbox></se-checkbox></se-table-item>
            <se-table-item flex="1" min-width="100px">{index + 1}</se-table-item>
            <se-table-item flex="1" min-width="100px">{ele.deviceid}</se-table-item>
            <se-table-item flex="1" min-width="100px">{ele.progress} %</se-table-item>
            <se-table-item flex="1" min-width="100px">{ele.message}</se-table-item>
            <se-table-item flex="1" min-width="100px">{versionInfo.version}</se-table-item>
            <se-table-item flex="1" min-width="100px">{ele.currentFirmware}</se-table-item>
        </se-table-group>
    })
    const onVersionChange = (e) => {
        setVersionInfo({ version: e.target.value })
        forDataToPost(scheduleSelecteion, e.target.value)
    }
    const getDate = (e) => {
        setInitialValue(e)
        forDataToPost(scheduleSelecteion, versionInfo.version)
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const onFileUpload = async () => {
        const file = document.querySelector('#customFile').files[0];
        // if ( /\.(bin)$/i.test(file.files[0].name) === false ) { console("not aa bin file!"); }
        if (!file) {
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: "warning", msg: "Please select a bin file to upload." })
            document.getElementById("customFile").value = ""
            return false;
        } else if (!file.name.match(/\.(bin)$/)) {
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: "warning", msg: "Only file with the format .bin is allowed." })
            document.getElementById("customFile").value = ""
            return false;
        } else if (!(file.size < 1000001)) {
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: "warning", msg: "Only bin file with Maximum 1MB size is allowed." })
            document.getElementById("customFile").value = ""
            return false;
        } else {
            let firmwareDetails = JSON.parse(JSON.stringify(dataToUploadBin))
            firmwareDetails.FirmwareFile = await toBase64(file)
            firmwareDetails.FirmwareFile = firmwareDetails.FirmwareFile.split(';base64,')[1];
            let fileName = file.name.split(".").slice(0, -1).join(".")
            firmwareDetails.FileName = fileName
            setDataToUploadBin(firmwareDetails)
        }
    }
    const onUploadVesrionChange = (e) => {
        // console.log(e.target.value)
        // if (/^\d{1}\.\d{1,2}\.\d{1,2}$/.test(e.target.value)) {
        //     Successful match
            document.getElementById("version").className="form-control"
            document.getElementById("validversionno").className="display-none"
        // } else {
        //     // Match attempt failed
        //     document.getElementById("validversionno").className="display-inline"
        //     document.getElementById("version").className="form-control error-border"
        //     //return;
        // }
        let firmwareDetails = JSON.parse(JSON.stringify(dataToUploadBin))
        firmwareDetails.Version = e.target.value
        setDataToUploadBin(firmwareDetails)
    }
    const dtValidation=(currentDate)=>{
        return currentDate.isAfter(moment(new Date().getTime()));
    }
    const uploadFirmware = () => {
        console.log(dataToUploadBin)
        // if (!/^\d{1}\.\d{1,2}\.\d{1,2}$/.test(dataToUploadBin.Version)) {
        //     return true
        // }
        if (dataToUploadBin.FileName === "" || dataToUploadBin.Version==="") {
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: "error", msg: "Please provide valid details to upload." })
            document.getElementById("customFile").value = ""
            return true
        }
        authCall.putUpdateFirmwareSetting(dataToUploadBin).then(response => {
        }).catch(error => {
            console.log(error);
        });
        document.getElementById('admin-snackbar').open = true;
        setSnackBarObj({ type: "success", msg: "Uploaded" })
        setTimeout(function () {
            fetchVersionDetails()
        }, 5000);
        
    }
    React.useEffect(() => {
        fetchVersionDetails()
        fetchDeviceStatusDetails()
    }, [])
    React.useEffect(() => {
        forDataToPost(scheduleSelecteion, versionInfo.version)
    }, [initialValue])
    React.useEffect(() => {
        if (selectedIndex === 0) {
            setBtnDisabled(true)
        }
    }, [selectedIndex])
    return <se-container position="grid" direction="row" margin="large" padding="large">
        <se-block option="fill" class="col-md-4 col-lg-3 col-xl-3 px-0">
            <se-block-content>
                <se-list>
                    <se-list-item item="FOTA" selected={true} />
                    {/* <se-list-item item="" />
                    <se-list-item item="" />
                    <se-list-item item="" /> */}
                </se-list>
            </se-block-content>
        </se-block>
        <se-divider option="vertical"></se-divider>
        <se-block>
            <se-tabbar color="alternative">
                <nav style={{ borderBottom: "1px solid lightgray" }}>
                    {list}
                </nav>
            </se-tabbar>
            <se-block-content>
                {selectedIndex === 0 ?
                    <div className="row">
                        <div className="col-md-4">
                            <label class="form-label" for="customFile"><strong>Upload</strong></label>
                            <input type="file" class="upload-file" id="customFile" onChange={(e) => onFileUpload(e)} />
                            <span className="Upload-info">Files must be (*.bin, Max size-1MB)</span>
                        </div>
                        <div className="col-md-4">
                            <label class="form-label" for="version"><strong>Version</strong></label>
                            <input type="text" class="form-control" id="version" placeholder="x.yy.zz" value={dataToUploadBin.Version} onChange={(e) => onUploadVesrionChange(e)} />
                            <span id="validversionno" className='display-none' style={{fontSize:"10px",color:"red",fontWeight:"600"}}>Please Enter Valid Version Number</span>
                        </div>
                        <div className="col-md-2">
                            <se-button option="flat" style={{ margin: "20px 0px" }} color="primary" onClick={() => { uploadFirmware() }}>Upload</se-button>
                        </div>
                        {/* <se-block class="col-md-12 mt-3">
                            <se-table style={{ border: "1px solid lightgray" }}>
                                <se-table-group-header>
                                    <se-table-item-header flex="1">SL No.</se-table-item-header>
                                    <se-table-item-header flex="5">File Name</se-table-item-header>
                                    <se-table-item-header flex="1">Delete</se-table-item-header>
                                </se-table-group-header>
                                <se-table-group selectable="true">
                                    <se-table-item flex="1">1</se-table-item>
                                    <se-table-item flex="5">
                                        <a href="https://villayapreprodblob.blob.core.windows.net/sem2000files/VillayaApplication1_1.bin">
                                            https://villayapreprodblob.blob.core.windows.net/sem2000files/VillayaApplication1_1.bin  </a></se-table-item>
                                    <se-table-item flex="1"><se-icon class="pr-2" size="small" color="standard">action_delete</se-icon></se-table-item>
                                </se-table-group>
                            </se-table>
                        </se-block> */}
                    </div>
                    :
                    <span>

                        <div class="row">
                            <div className="col-md-4">
                                <se-radio-group style={{ display: "-webkit-inline-box" }}>
                                    <se-radio value="0" label="Schedule Now" onClick={(e) => onScheduleChange(e.target.value)}></se-radio>
                                    <se-radio value="1" label="Schedule Later" onClick={(e) => onScheduleChange(e.target.value)}></se-radio>
                                </se-radio-group>
                                <Datetime inputProps={inputProps} isValidDate={dtValidation} value={initialValue} onChange={(e) => getDate(e)} />
                            </div>
                            <div className="col-md-4">
                                <label class="form-label" for="customFile"><strong>Version</strong></label>
                                <input type="text" class="form-control" onChange={(e) => onVersionChange(e)} value={versionInfo.version} />
                            </div>
                            <div className="col-md-2">
                                <se-button option="flat" style={{ margin: "20px 0px" }} color="primary" disabled={btnDisabled} onClick={() => schedule()}>Schedule</se-button>
                            </div>
                        </div>

                        {/* <se-form-field disabled={true} option="stacked" label="version">
                            <input type="text" value={versionInfo.version} />
                        </se-form-field> */}

                        {/* <se-block>
                            <se-table>
                                <se-table-group-header>
                                    <se-table-item-header width="53px"><se-checkbox></se-checkbox></se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px">SL No.</se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px" clickable="true"
                                    >Meter  S/N.</se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px">Status</se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px">Message</se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px">Target Vesrion</se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px">Current Vesrion</se-table-item-header>
                                </se-table-group-header>
                                {tableGroup}
                            </se-table>
                        </se-block> */}
                    </span>}

            </se-block-content>
        </se-block>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container >
}
export default withRouter(AdminPage);