import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Auth from '../api/ApiSession'
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash';
import "react-datetime/css/react-datetime.css";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import Compressor from 'compressorjs';
const authCall = new Auth()
const Gallery = () => {
    const [photoImg, setPhotoImg] = React.useState({ "fileName": "", "photo": "" })
    const [photoList, setPhotoList] = React.useState([])
    const [photoDeleteList, setphotoDeleteList] = React.useState([])
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [show, setShow] = React.useState(false);
    const [showImg, setShowImg] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [isFetching, setIsFetching] = React.useState(false);
    // const [compressedFile, setCompressedFile] = useState(null);

    const fetchCustomerDetails = () => {
        var pgCount=2
        photoImg.userid = sessionStorage.getItem("userId")
        photoImg.customerId = sessionStorage.getItem("customerId")
        photoImg.customerType = sessionStorage.getItem("customerType")
        photoImg.pageNumber = page
        photoImg.pageCount=pgCount
        console.log(photoList, photoList.length,page)
        authCall.getGalleryPhoto(photoImg).then(response => {
            appendPhotos(response);
            if (response.length > pgCount-1) {
                setIsFetching(true);
            }
            else{
                setPage(1);
            }

        }).catch(error => {
            console.log(error);
        });
    }
    const appendPhotos=(res)=>{
        let arr=photoList.concat(res).filter((o, index, self) =>
        index === self.findIndex((t) => (t.id === o.id))
      )
        setPhotoList(arr)
    }
    const getMorePhotos=()=>{
        let p=page+1
        setPage(p)
        setIsFetching(false);       
            fetchCustomerDetails()
    }
    const onImageUpload = (e) => {
        let file = e.target.files[0];
        if (!file) {
            // this.setState({ ImageIsValid: this.state.ImageIsValid = false });
            document.getElementById('admin-snackbar').open = true;
            // this.setState({ warningMsg: 'Please select an image to upload.' });
            // this.setState({ warningMsgType: "warning" });
            setSnackBarObj({ type: "warning", msg: "Please select an image to upload." })
            document.getElementById("customFile").value = ""
            // console.log(snackBarObj)
            return false;
        } else if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            // this.setState({ ImageIsValid: this.state.ImageIsValid = false });
            document.getElementById('admin-snackbar').open = true;
            // this.setState({ warningMsg: 'Only images with the format .png, .jpg, .jpeg are allowed.' });
            // this.setState({ warningMsgType: "warning" });
            setSnackBarObj({ type: "warning", msg: "Only images with the format .png, .jpg, .jpeg are allowed." })
            document.getElementById("customFile").value = ""
            // console.log(snackBarObj)
            return false;
        } else if (!(file.size < 2000001)) {
            // this.setState({ ImageIsValid: this.state.ImageIsValid = false });
            document.getElementById('admin-snackbar').open = true;
            // this.setState({ warningMsg: 'Failed to upload an image. Maximum size allowed 2MB only.' });
            // this.setState({ warningMsgType: "warning" });
            setSnackBarObj({ type: "warning", msg: "Only images with Maximum 2MB size is allowed." })
            document.getElementById("customFile").value = ""
            // console.log(snackBarObj)
            return false;
        } else {
            // console.log(file)
            new Compressor(file, {
                quality: 80, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.  
                    // setCompressedFile(res)
                    // console.log(compressedResult)
                    let reader = new FileReader();
                    reader.readAsDataURL(compressedResult);
                    reader.onloadend = () => {
                        // console.log(reader.result);
                        setPhotoImg({ "fileName": file.name, "photo": reader.result })
                    };
                },
            });

        }
    }

    const uploadPhoto = () => {
        if (photoImg.photo === "") {
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: "error", msg: "Please select a valid image to upload." })
            document.getElementById("customFile").value = ""
            return true
        }
        photoImg.userid = sessionStorage.getItem("userId")
        photoImg.siteName = sessionStorage.getItem("siteName")
        photoImg.customerId = sessionStorage.getItem("customerId")
        photoImg.customerType = sessionStorage.getItem("customerType")
        console.log(photoImg)

        authCall.PostGalleryPhoto(photoImg).then(response => {
            let typ = response[0].res === "Site image uploaded sucessfully" ? "success" : "error"
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: typ, msg: response[0].res })
            document.getElementById("customFile").value = ""
            setPhotoImg({ "fileName": "", "photo": "" })
            
            fetchCustomerDetails()

        }).catch(error => {
            console.log(error);
        });

    }
    const onDelete = () => {
        if (photoDeleteList.length < 1) {
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: "warning", msg: "Please select an image to delete." })
            return;
        }

        console.log(photoDeleteList)
        authCall.deletePhoto(photoDeleteList).then(response => {
            let typ = response[0].res === "Site image deleted sucessfully" || response[0].res === "Site images deleted sucessfully" ? "success" : "error"
            document.getElementById('admin-snackbar').open = true;
            setSnackBarObj({ type: typ, msg: response[0].res })
            let arr = document.getElementsByName("checkbox")
            for (let i = 0; i < arr.length; i++) {
                arr[i].selected = false
            }
            setphotoDeleteList([])
            setPhotoList([])
            setIsFetching(true);  
            fetchCustomerDetails()
        }).catch(error => {
            console.log(error);
        });

    }
    const onCheckBoxClicked = (status, index, id) => {
        if (status) {
            setphotoDeleteList([...photoDeleteList, id])
        }
        else {
            setphotoDeleteList(photoDeleteList.filter((p) => {
                return p !== id
            }))
        }

    }
    const handleClose = () => setShow(false);
    const handleShow = (list) => {
        setShow(true)
        setShowImg(list.blobUrl)

    }

    const LoadTable = (list, index) => {
        return (<div className="col-md-3" style={{ border: '1px solid #F7F7F7' }}>
            <span style={{ position: "relative" }}>
                {sessionStorage.getItem("customerId") === "0" ? null : <se-checkbox name="checkbox" onClick={(e) => onCheckBoxClicked(e.target.selected, index, list.id)}></se-checkbox>}
                {list.photo_filename}</span>
            <img src={list.blobUrl} width="100%" onClick={() => handleShow(list)} style={{ maxHeight: '240px' }} class="imageclass" />
        </div>)
    }
    React.useEffect(() => { fetchCustomerDetails() }, []);
    React.useEffect(() => {
        if (!isFetching) return;
        getMorePhotos();
      }, [isFetching]);
    return <se-container position="grid" direction="row" margin="large" padding="large">
        <se-block className="col-md-12">
            <se-block-content>
                {sessionStorage.getItem("customerId") === "0" ? null :
                    <div className="row" style={{ paddingBottom: '25px' }}>
                        <div className="col-md-3"><label style={{ marginTop: "5px" }} class="form-label float-right" for="customFile"><strong>Upload Site Images</strong></label></div>
                        <div className="col-md-4">
                            <input type="file" class="upload-file" id="customFile" onChange={(e) => onImageUpload(e)} />
                            <span className="Upload-info">Files must be (*.jpg|.jpeg|.png, Max size-2MB)</span>
                        </div>
                        <div className="col-md-4">
                            <se-button option="flat" style={{ marginTop: "-5px" }} color="primary" onClick={() => { uploadPhoto() }}>Upload</se-button>
                            <se-button option="flat" style={{ marginTop: "-5px" }} color="standard" onClick={() => { onDelete() }}>Delete</se-button>
                        </div>

                    </div>
                }


                <div className="bs-example nav-content">
                    <div className="row">
                        {photoList.map(LoadTable)}
                        {show ? <Lightbox image={showImg} onClose={handleClose} allowZoom={true} allowRotate={false}></Lightbox> : null}
                    </div>
                </div>
            </se-block-content>
        </se-block>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container >
}
export default withRouter(Gallery);