import React from 'react';
import useMycustomerForm from '../myCustomersComponents/useMycustomerForm';
const _ = require('lodash');
const IncomeDetails2 = (props) => {
    const { errors } = useMycustomerForm(props.infoData, props.index)
    const onValueChange = (e, index) => {
        let { value } = e.target
        props.infoData[index].PropValue = value
        props.onBlur(props.infoData, props.index)
    }
    props.onChange(errors)
    const renderError = (data, index) => {
        if (errors[index].PropName === data.PropName && errors[index].PropMsg) {
            return <div className="se-error-text">                
                {errors[index].PropMsg}</div>        }
    }
    const renderClass = (data, index) => {
        if (errors[index].PropName === data.PropName && errors[index].PropMsg) {
            return "se-form-control " + errors[index].PropClass
        } else {
            return "se-form-control"
        }
    }
    return <se-block display="grid">
        <div className="rows" align="">
            <div className="col-md-6 px-0">
                {_.map(props.infoData, (eachData, index) => {
                    return <div class="createBlockSize text-right pb-3">
                        <label className="col-sm-6 col-lg-6 col-xl-5 se-label height20">{eachData.PropName}
                            {eachData.PropName === "Annual Income (INR)" ? null : <span class="se-mandatory">*</span>}
                        </label>
                        <div className="col-md-6 px-0 text-left">
                            <input type="text" style={{ width: '100%' }} className={renderClass(eachData, index)} placeholder="" autoComplete="off" name={eachData.PropName}
                                disabled={props.isDisabled} value={eachData.PropValue} onChange={(e) => onValueChange(e, index)} />
                            {renderError(eachData, index)}
                        </div>
                    </div>
                })}
            </div>
        </div>
    </se-block>
}
export default IncomeDetails2