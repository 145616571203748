import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import 'react-day-picker/lib/style.css';
import { Line, Bar } from 'react-chartjs-2'
import Auth from '../api/ApiSession';
import './styles.css';
import ChoosePeriod from '../components/choosePeriod';
import domtoimage from 'dom-to-image';
import useImpactMetricsGraphDetails from './useImpactMetricsGraphDetails';
const authCall = new Auth();
const RealtimeMonitoringDetails = (props) => {
    const [utilityDetails, setUtilityDetails] = React.useState({})
    const [utilityDetailsCopy, setUtilityDetailsCopy] = React.useState({})
    const [selectedCheckBox, setSelectedCheckBox] = React.useState([])
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [graphType, setGraphType] = React.useState({
        barGraphClass: "iconBtnLeft active",
        lineGraphClass: "iconBtnRight",
        type: 'Bar'
    })
    const [dataToPost, setDataToPost] = React.useState({
        userId: sessionStorage.getItem("userId"),
        type: "Energy Consumption",
        granularity: null,
        startDate: null,
        endDate: null,
        siteName: sessionStorage.getItem("siteName"),
        customerId: parseInt(sessionStorage.getItem("customerId")),
        customerType: sessionStorage.getItem("customerType"),
        graphType:graphType.type
    })
    const intervalRef = React.useRef()
    let idToPrint = []
    let dataValue = {}
    let options = {}
    const onPeriodChange = (startDate, endDate, type) => {
        let dataTopost = dataToPost
        dataTopost.startDate = startDate
        dataTopost.endDate = endDate
        dataTopost.granularity = type
        setDataToPost(dataTopost)
        fetchUtilityDetails('normalCall')
    }
    const { renderRealtimeGraphDetails, renderRealtimeInfoTable } = useImpactMetricsGraphDetails(utilityDetails, graphType, idToPrint, dataValue, options, utilityDetails.widgetType)
    const fetchUtilityDetails = (call) => {
        call === "normalCall" ? setIsLoading(true) : setIsLoading(false);
        authCall.postAgroDashboardDetails(dataToPost).then(json => {
            if (json) {
                
                setIsLoading(false);
                let jsonCopy = JSON.parse(JSON.stringify(json));
                removeDataFromGraph(jsonCopy)
                setUtilityDetailsCopy(json)
            }
        })
    }
    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            fetchUtilityDetails('initialCall')
        }, 900000);
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])
    const removeDataFromGraph = (jsonCopy) => {
        let selectedIndex = null
        _.map(selectedCheckBox, ele => {
            _.map(jsonCopy.widgetDetails, widgetDetail => {
                let i = 0;
                while (i < widgetDetail.graphData.length) {
                    if (widgetDetail.graphData[i].propName === ele) {
                        widgetDetail.graphData.splice(i, 1)
                        i = 0;
                    } else {
                        i++;
                    }
                }
            })
            _.map(jsonCopy.tableData, tableData => {
                _.map(tableData.tableValue, tableValue => {
                    _.map(tableValue.propValue, (propValue, index) => {
                        if (propValue === ele) {
                            selectedIndex = index
                        }
                    })
                })
            })
            _.map(jsonCopy.tableData, tableData => {
                _.map(tableData.tableValue, tableValue => {
                    if (selectedIndex >= 0) {
                        tableValue.propValue.splice(selectedIndex, 1)
                    }
                })
            })
        })
        setUtilityDetails(jsonCopy)
    }
    const changeToBarGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft active",
            lineGraphClass: "iconBtnRight",
            type: "Bar"
        })
        dataToPost.graphType="bar"
    }
    const changeToLineGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft",
            lineGraphClass: "iconBtnRight active",
            type: "Line"
        })
        dataToPost.graphType="Line"
    }
    const navigateToOverview = () => {
        let { history } = props;
        history.push({
            pathname: '/overview'
        });

    }
    const onCheckBoxClicked = (selected, selectedData) => {
        let utilityDetails1 = JSON.parse(JSON.stringify(utilityDetailsCopy));
        if (selected === false) {
            if (selectedCheckBox.indexOf(selectedData.propName) === -1) {
                selectedCheckBox.push(selectedData.propName)
            }
        } else {
            if (selectedCheckBox.indexOf(selectedData.propName) !== -1) {
                selectedCheckBox.splice(selectedCheckBox.indexOf(selectedData.propName), 1)
            }
        }
        removeDataFromGraph(utilityDetails1)
    }
    const generateReport = () => {
        
        setIsLoading(true)
        
            authCall.postGeneratePdfReport(dataToPost).then(json => {
                if (json) {
                    setIsLoading(false)
                    let a = document.createElement("a")
                    a.href =json.includes("\"https://")? "https://"+json.replace("\"https://",'').replace('"',''):json
                    a.target= '_blank'
                    a.click()
                }else{
                    setIsLoading(false)
                    document.getElementById('admin-snackbar').open = true;
                    setSnackBarObj({ type: "warning", msg: "Failed to generate PDF" })
                }
            })
        // })
    }
    const renderCheckBoxList = _.map(utilityDetails.widgetData, (ele, index) => {
        if (ele.propIcon) {
            return <div key={index} class="row align-items-center">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
                    <se-icon size="large" color="standard">{ele.propIcon}</se-icon>
                </div>
                <div class="col-md-3  px-0">
                    <span class="font-size-12 textColor">{ele.propName}</span>
                </div>
                <div class="col-md-7">
                    <span class="font-size-37" style={{ position: 'relative' }}>{ele.propVal}
                        <span className={'displayUnits'}>{ele.units}</span></span>
                </div>
            </div>
        } else {
            return <div key={index} class="row align-items-center">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 font-normal">{ele.propName}</div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-5 px-0 text-right">
                    <se-checkbox selected="true" onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}></se-checkbox>
                </div>
            </div>
        }
    })
    return <se-container position="absolute" direction="row" >
        {isLoading ? <div class="align-items-center"><se-loading loading="true"></se-loading></div> : null}
        <se-block>
            <se-block-header class="headerColor"><span class="font-size-22">
                {utilityDetails.widgetType}-{sessionStorage.getItem("siteName") !== "" ?
                    sessionStorage.getItem("siteName") : "All Sites"}</span>
                <div slot="end"><se-icon option="" class="pointer" onClick={() => navigateToOverview()}>action_close</se-icon>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </se-block-header>
            <se-block-content class="grey-backgroundCreate" >
                <se-container position="absolute" direction="row" margin="large" padding="large">
                    <div class="col-md-4 col-lg-3 col-xl-3 px-0" option="widget" margin="none" style={{background:"white"}}>
                        <div class="border-bottom divDetailsHeight text-left">
                            <span class="font-normal font-weight-400">Last updated: {utilityDetails.lastUpdated}</span>
                        </div>
                        <div class="border-bottom" style={{visibility:sessionStorage.getItem('siteName')===""?'hidden':'visible'}}>
                            <se-block-content>
                                <ChoosePeriod onChange={onPeriodChange} InstaltedDate={utilityDetails.installationDate} />
                            </se-block-content>
                        </div>
                        <se-block-content class="ChooseDivHeight">
                            {renderCheckBoxList}
                        </se-block-content>
                    </div>
                    <se-divider option="vertical"></se-divider>
                    <se-block class="px-0">
                        <div class="rows pdf-download">
                            {sessionStorage.getItem('siteName') === "" ? null : <div className="mt-2 toggleBtn">
                                <span onClick={() => changeToBarGraph()} className={graphType.barGraphClass}>
                                    <se-icon >graph_barchart</se-icon>
                                </span>
                                <span onClick={() => changeToLineGraph()} className={graphType.lineGraphClass}>
                                    <se-icon >energy</se-icon></span>
                            </div>}
                            <se-icon option="button" onClick={() => generateReport()} color="alternative" size="large">action_pdf_report</se-icon>
                        </div>
                        {renderRealtimeGraphDetails()}
                        <div style={{ width: "93%" }}>
                            {renderRealtimeInfoTable()}
                        </div>
                    </se-block>
                </se-container>
            </se-block-content>
        </se-block>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container>
}
export default withRouter(RealtimeMonitoringDetails);