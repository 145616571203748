export default function validateSigupForm(values, name) {   
    let errors = {}
    errors.name = name
    if (values.FirstName) {
        if (!/^[ a-zA-Z]+$/.test(values.FirstName)) {
            errors.FirstNameClass = "error-border"
            errors.FirstName = "Enter Valid Name"
        } else if(values['FirstName'].length > 20){
            errors.FirstNameClass = "error-border"
            errors.FirstName = "Please enter less than 20 letters"
        } 
        else {
            errors.FirstNameClass = "valid-border"
            errors.FirstName = ""
        }
    }
    if (values.LastName) {
        if (!/^[ a-zA-Z]+$/.test(values.LastName)) {
            errors.LastNameClass = "error-border"
            errors.LastName = "Enter Valid Name"
        } else if(values['LastName'].length > 20){
            errors.LastNameClass = "error-border"
            errors.LastName = "Please enter less than 20 letters"
        } else {
            errors.LastNameClass = "valid-border"
            errors.LastName = ""
        }
    }
    if (values.EmailId) {
        if (!/^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i.test(values.EmailId)) {
            errors.EmailIdClass = "error-border"
            errors.EmailId = "Enter Valid Email"
        } else {
            errors.EmailIdClass = "valid-border"
            errors.EmailId = ""
        }
    }

    if (values.Address) {
        if (!/^[ a-zA-Z0-9\./#,-]+$/i.test(values.Address)) {
            errors.AddressClass = "error-border"
            errors.Address = "Only alphanumeric and ./#,-"
        } else if(values['Address'].length > 255){
            errors.AddressClass = "error-border"
            errors.Address = "Please enter less than 255 letters"
        }else {
            errors.AddressClass = "valid-border"
            errors.Address = ""
        }
    }
    if (values.CompanyAddress) {
        if (!/^[ a-zA-Z0-9\./#,-]+$/i.test(values.CompanyAddress)) {
            errors.CompanyAddressClass = "error-border"
            errors.CompanyAddress = "Only alphanumeric and ./#,-"
        }  else if(values['CompanyAddress'].length > 50){
            errors.CompanyAddressClass = "error-border"
            errors.CompanyAddress = "Please enter less than 50 letters"
        }else {
            errors.CompanyAddressClass = "valid-border"
            errors.CompanyAddress = ""
        }
    }


    if (values.ContactNo) {
        if (!/^\d{10}$/i.test(values.ContactNo)) {
            errors.ContactNoClass = "error-border"
            errors.ContactNo = "Phone Number is not valid"
        } else {
            errors.ContactNoClass = "valid-border"
            errors.ContactNo = ""
        }
    }
    if (values.Zipcode) {
        if (!/^\d{6}$/i.test(values.Zipcode)) {
            errors.ZipcodeClass = "error-border"
            errors.Zipcode = "Pincode Number is not valid"
        } else {
            errors.ZipcodeClass = "valid-border"
            errors.Zipcode = ""
        }
    }
    if (values.ConfirmPassword) {
       if(values.Password !==values.ConfirmPassword){
        errors.ConfirmPasswordClass = "error-border"
        errors.ConfirmPassword = "Password do not match"
    } else {
        errors.ConfirmPasswordClass = "valid-border"
        errors.ConfirmPassword = ""
    }
    }
    if (values.Password && values.ConfirmPassword) {
        if(values.Password !==values.ConfirmPassword){
         errors.PasswordClass = "error-border"
         errors.Password = "Password do not match"
     } else {
         errors.PasswordClass = "valid-border"
         errors.Password = ""
     }
     }

    return errors
}