import React, { Component } from 'react';
import land from '../images/landImg.png'
import agri from '../images/agri.PNG'
import solarwaterpumping from '../images/solarwaterpumping.PNG'
const LandingPage = (props) => {
  const getAgroDetails = () => {
    sessionStorage.setItem("customerType", 'Agro');
    sessionStorage.setItem("customerId", parseInt(0));
    sessionStorage.setItem("siteName", "");
    let { history } = props;
    history.push({
        pathname: '/overview'
    });
  }
  const getSWPDetails = () => {
    sessionStorage.setItem("customerType", 'SWP');
    sessionStorage.setItem("siteName", "");
    sessionStorage.setItem("customerId", parseInt(0));
    let { history } = props;
    history.push({
        pathname: '/overview'
    });
  }
  return <se-container>
    <div className='container-fluid'>
      <div className='row' style={{ padding: '25px 15px' }}>
        <div className='col-6' style={{ borderRight: '2px solid #CBCBCB'}}>
          <div className='row'>
            <div className='col-4 text-center'><img src={agri} width='200' style={{ borderRadius: '10%', overflow: 'hidden' }} /></div>
            <div className='col' style={{ fontSize: '24px', lineHeight: '40px', paddingTop: '5%',cursor:"pointer" }} >
              <span className='link_txt' onClick={getAgroDetails}>Villaya Agri</span>
              </div>
          </div>
          <div className='row'>
            <p className='d-flex justify-content-center' style={{ padding: '15px' }}>the agriculture pump will continue to run at
              rated capacity taking power from solar panels and balance power from grid, if
              required, and in case solar power generation is higher than required by pump, the
              additional solar power would be fed to the grid. Farmer would be able to import and
              export power to the grid at the rate specified by the concerned State
              Government/SERC.
            </p>
          </div>
          <div className='row' style={{float:'right'}}>
            <se-button style={{padding:'25vh 5vh 10vh 5vh'}} option="flat" color="primary" onClick={getAgroDetails}>Visit</se-button>
          </div>
        </div>
        <div className='col-6'>
          <div className='row'>
            <div className='col-4 text-center'><img src={solarwaterpumping} width='200' style={{ borderRadius: '10%', overflow: 'hidden' }} /></div>
            <div className='col' style={{ fontSize: '24px', lineHeight: '40px', paddingTop: '5%',cursor:"pointer" }}>
              <span className='link_txt' onClick={getSWPDetails}>Villaya Solar Water Pumping</span>
              </div>
          </div>
          <div className='row'>
            <p className='d-flex justify-content-center' style={{ padding: '15px' }}>the agriculture pump will continue to run at
              rated capacity taking power from solar panels and balance power from grid, if
              required, and in case solar power generation is higher than required by pump, the
              additional solar power would be fed to the grid. Farmer would be able to import and
              export power to the grid at the rate specified by the concerned State
              Government/SERC.
            </p>
          </div>
          <div className='row' style={{float:'right'}}>
            <se-button style={{padding:'25vh 5vh 10vh 5vh'}} option="flat" color="primary" onClick={getSWPDetails}>Visit</se-button>
          </div>
        </div>
      </div>
    </div>

  </se-container>
}
export default LandingPage
