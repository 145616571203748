import React from 'react'
const ToolTipInfo = (props) => {
    return <se-tooltip position="right" class="tooltip-info" style={props.styleProp}>
        <se-icon size="large" color="information" option="raised" slot="tooltip"
            >help</se-icon>
        <div class="tooltip-container">
            <strong>INVALID_CAPACITY_UTILIZATION_VALUE</strong>
            <ul>
                <li> &#9679; Check missing value(s) for energy</li>
                <li> &#9679; Check incorrect values for IR/PV has &nbsp;&nbsp;&nbsp;&nbsp;been entered</li>
            </ul>
        </div>
    </se-tooltip>
}
export default ToolTipInfo