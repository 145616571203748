import React from "react";
import { AuthConsumer } from "./authProvider";

export const Callback = () => (
    // window.location.href=OidcSettings.authority
    <AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();
            return  <se-loading loading="true"></se-loading>;
        }}
    </AuthConsumer>
);