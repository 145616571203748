import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

class DefaultLayout extends Component {
  render() {
    return (
      <se-app>
        {this.props.children}
      </se-app>
    );
  }
}

export default withRouter(DefaultLayout);

