import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'bootstrap/scss/bootstrap.scss';
import Auth from '../api/ApiSession';
import moment from 'moment'
import _ from 'lodash';
import { bool, number } from 'prop-types';
const authCall = new Auth();

class UserManagement extends Component {
    // intervalID = 0;
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            usersList: [],
            usersLength: number,
            activeusersList: [],
            pendinguserslist: [],
            rejectedUsers: [],
            SelectedUserList: bool,
            tableData: [],
            value: number,
            isEdit:true,
            selectedRole:number,
            isBlock:true,
            isApprove:false,
            isReject:false,
            rolesList:[],
            selectedItem:{},
            snackBarObj:{},
            expandRole:false
        }
    }
    componentWillMount() {
        // this.onCurrentStatus();
        this.fetchUsersList()
        this.fetchRolesList()
    }

    fetchRolesList() {

        authCall.getRolesList().then(entries => {
            this.setState({ rolesList: entries });
        }).catch(error => {
            console.log(error);
        });
    }
    fetchUsersList() {

        authCall.getUsersList().then(entries => {
            this.setState({ usersList: entries });
            this.setState({ tableData: entries });
            this.setState({ usersLength: entries.length });
            this.setState({ activeusersList: entries.filter(a => a.isactive === true) });
            this.setState({ pendinguserslist: entries.filter(a => a.isactive === null) });
            this.setState({ rejectedUsers: entries.filter(a => a.isactive === false) });
        }).catch(error => {
            console.log(error);
        });
    }
    onItemSelect (item,val) {
        this.setState({ selectedItem: item });
        if(val===0){
            document.getElementById('dialog-simple-notitle').open = true;
        }
        else{
            document.getElementById('dialog-approve').open = true;
        }
        
      }
      onApproveSelect(item){

        this.setState({ selectedItem: item });
        this.setState({ expandRole: true });
        this.setState({ isCancel: true });
        this.setState({ isEdit: false });
      }
      onCancleSelect(){
        this.setState({ isEdit: true });
        this.setState({ isCancel: false });
        this.setState({ expandRole: false });
      }

      UpdateCustomerStatus(role,val){
        let data={UserId:this.state.selectedItem.id,FederatedId:this.state.selectedItem.federatedId,RoleId:role,status:val,username:sessionStorage.getItem("loginUserName")}
        console.log(data)

        authCall.updateUserRole(data).then(json => {
            console.log(json)
          if (json[0].ack===1) {
            document.getElementById('dialog-simple-notitle').open = false;
            document.getElementById('dialog-approve').open = false;
            document.getElementById("mycustomer-snackbar").open = true;
            this.setState({snackBarObj:{ type: "success", msg: json[0].res }})
            this.fetchUsersList()
            this.setState({ selectedRole: 0});
            
          }
          else{
            document.getElementById("mycustomer-snackbar").open = true;
            this.setState({snackBarObj:{ type: "error", msg: json[0].res }})
          }
        })
          .catch(error => {
            console.log(error);
          });
      }
    LoadSelectedUserList(val) {
        console.log(val)
        this.setState({ value: val });
        this.setState({ SelectedUserList: false });
        if (val === 1) {
            this.setState({ tableData: this.state.activeusersList });
        }
        else if (val === 2) {
            this.setState({ tableData: this.state.pendinguserslist });
        }
        else if (val === 3) {
            this.setState({ tableData: this.state.rejectedUsers });
        }
        else {
            this.setState({ SelectedUserList: true });
            this.setState({ tableData: this.state.usersList });
        }
    }
    renderOption(){
        return _.map(this.state.rolesList, ele => {
            return <option class="clsOptions" value={ele.id}>{ele.role_name}</option>
        })
    }
    onValueChange(val){
        this.setState({ selectedRole: parseInt(val) });
    }
    LoadTable = (item, index) => {
        return (
            <se-block class="clsUserCard">
                <se-container display="flex" padding="medium">
                    <se-block margin="large">
                        <span class="clsUserCardHdr"> User Name </span>
                        <span class="clsUserCardDet"> {item.username} </span>
                    </se-block>
                    <se-block margin="large">
                        <span class="clsUserCardHdr"> User Role </span>
                        <span class="clsUserCardDet"> {item.user_type} </span>
                    </se-block>
                    {/* <se-block margin="large">
                        <span class="clsUserCardHdr"> Email ID </span>
                        <span class="clsUserCardDet overme"> {item.email}</span>
                    </se-block> */}
                    <se-block margin="large">
                        <span class="clsUserCardHdr"> Status </span>
                        <span class="clsUserCardDet"> {item.isactive===true?"Active":item.isactive===false?"Rejected":"Pending"} </span>
                    </se-block>
                    <se-block margin="small" width="15%">
                        {this.state.isApprove?<se-button option="outline" size="small" type="button" block="true"
                            onClick={() => { this.onItemSelect(item,1)}}>
                            Approve
                        </se-button>:null}
                        {this.state.isEdit?<se-button option="outline" size="small" type="button" block="true"
                            onClick={() => { this.onItemSelect(item,1)}} >
                            Edit
                        </se-button>:null}
                        {this.state.isReject ?<se-button option="outline" size="small" type="button" block="true"
                            onClick={() => { this.onItemSelect(item,0)}}>
                            Reject
                        </se-button>:null}
                        {this.state.isBlock?<se-button option="outline" size="small" type="button" block="true"
                            onClick={() => { this.onItemSelect(item,0)}} >
                            Block
                        </se-button>:null}
                    </se-block>
                </se-container>
                {this.state.expandRole && item.id===this.state.selectedItem.id?<div><se-divider class="clsDivider" ></se-divider>
                <se-container padding="small">
                <se-block display="flex">
                    <se-block-content>
                        <span class="clsUserCardHdr"> Select User Type </span>
                        <select  class="clsRoleOption" onChange={(e) => this.onValueChange(e.target.value)}>
                            {this.renderOption()}
                        </select>
                        <se-button option="flat" color="primary" onClick="Approve(user)"> Confirm </se-button>
                    </se-block-content>
                </se-block>
            </se-container></div>:null}
            </se-block>
        )
    }

    searchText(value) {
        let searchString = value.toLowerCase()
        this.setState({ searchText: value })
        let responseData = []
        if (searchString.length > 0) {
            responseData = this.state.notificationData.filter(item => {
                return item.notificationDescription.toLowerCase().indexOf(searchString) !== -1;
            })
            this.state.notificationTable = responseData
        } else {
            this.state.notificationTable = this.state.notificationData
        }

        this.paginate(1);

        window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({'event': 'Search_Notification'});
        window.dataLayer.push({
            'event': 'Search_Notification',
            'Search_Text': value
        });


    }
    render() {
        if (this.state.count === 0) {
            this.props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
            this.setState({ count: this.state.count + 1 })
        }
        const options = [];
        options.push(<option value="0">All Sites</option>);
        for (let i = 0; i <= this.state.CustomerlistLength - 1; i++) {
            const siteList = this.state.siteList;
            if (siteList[i].location) {
                options.push(<option value={siteList[i].customerId}>{siteList[i].location}</option>);
            }
        }
        return (
            <div>
                {this.state.SelectedUserList ?
                    <se-container display="block">
                        <se-block margin="small">
                            <span class="clsGreenHdr">Total Users ({this.state.usersLength})</span>
                        </se-block>
                        <se-block margin="small">
                            <se-container display="flex">
                                <se-block width="6em">
                                    <span class="clsSpnUserStatus" onClick={() => this.LoadSelectedUserList(1)}>
                                        Active -
                                        <span class="clsSpnUserStatusCount"> {this.state.activeusersList.length}</span>
                                    </span>
                                </se-block>
                                <se-block width="1em">
                                    <span class="clsSpanDivider"> | </span>
                                </se-block>
                                <se-block width="11em">
                                    <span class="clsSpnUserStatus" onClick={() => this.LoadSelectedUserList(2)}>
                                        Pending Approval -
                                        <span class="clsSpnUserStatusCount"> {this.state.pendinguserslist.length}</span>
                                    </span>
                                </se-block>
                                <se-block width="1em">
                                    <span class="clsSpanDivider"> | </span>
                                </se-block>
                                <se-block width="7em">
                                    <span class="clsSpnUserStatus" onClick={() => this.LoadSelectedUserList(3)}>
                                        Rejected -
                                        <span class="clsSpnUserStatusCount"> {this.state.rejectedUsers.length}</span>
                                    </span>
                                </se-block>
                            </se-container>
                        </se-block>
                    </se-container> :
                    <se-container display="flex">
                        <se-block margin="small" display="flex">
                            <se-block-content option="fill" style={{ paddingTop: "1%" }}>
                                <span class="clsHomeMenu" onClick={() => this.LoadSelectedUserList(0)}>Total Users {'>'} </span>
                                {this.state.value === 1 ? <span class="clsGreenHdr">
                                    Active ({this.state.activeusersList.length})
                                </span>
                                    : this.state.value === 2 ?
                                        <span class="clsGreenHdr" >
                                            Pending Approval ({this.state.pendinguserslist.length})
                                        </span>
                                        : this.state.value === 3 ?
                                            <span class="clsGreenHdr">
                                                Rejected ({this.state.rejectedUsers.length})
                                            </span> : null
                                }
                            </se-block-content>
                        </se-block>
                    </se-container>
                }
                {this.state.tableData.map(this.LoadTable)}

                <se-dialog id="dialog-simple-notitle" color="alternative" size="small" can-backdrop="false">
                    <se-dialog-content><div class="font-size-14 pb-4">Are you sure you want to Reject the User?</div></se-dialog-content>
                    <se-dialog-footer align="right">
                    <se-button onclick="document.getElementById('dialog-simple-notitle').open = false">Cancel</se-button>
                    <se-button onClick={() => this.UpdateCustomerStatus(0,0)}>OK</se-button>
                    </se-dialog-footer>
                </se-dialog>
                <se-dialog id="dialog-approve" color="alternative" size="small" can-backdrop="false">
                    <se-dialog-content>
                        <span class="clsUserCardHdr"> Select User Type </span>
                        <select  class="clsRoleOption" style={{width:"60%"}} onChange={(e) => this.onValueChange(e.target.value)}>
                        <option class="clsOptions" value="">Select Role</option>
                            {this.renderOption()}
                        </select></se-dialog-content>
                    <se-dialog-footer align="right">
                    <se-button onclick="document.getElementById('dialog-approve').open = false">Cancel</se-button>
                    <se-button onClick={() => this.UpdateCustomerStatus(this.state.selectedRole,1)}>OK</se-button>
                    </se-dialog-footer>
                </se-dialog>
                <se-snackbar id="mycustomer-snackbar" duration="5000" type={this.state.snackBarObj.type} message={this.state.snackBarObj.msg} ></se-snackbar>
            </div>
        );
    }
}

export default withRouter(UserManagement);
