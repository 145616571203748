import React from 'react'
import ChoosePeriod from '../components/choosePeriod';
import Auth from '../api/ApiSession';
import _, { each } from 'lodash';
import domtoimage from 'dom-to-image';
import useImpactMetricsGraphDetails from './useImpactMetricsGraphDetails';
const CreateReport = (props) => {
    const authCall = new Auth();
    const [location, setLocation] = React.useState([])
    const [customer, setCustomer] = React.useState([])
    const [installtionDate, setInstalltionDate] = React.useState(new Date())
    const [setCustomerId, setSelectedCustomerId] = React.useState()
    const [initialLocation, setInitialLocation] = React.useState("Choose location")
    const [initialCustomer, setInitialCustomer] = React.useState("Choose customer")
    const [customerList, setCustomerList] = React.useState([{ "installedDate": null, "customerId": null, "Customers": "Choose customer", city: null, state: null, location: null }])
    const [isLoading, setIsLoading] = React.useState(false)
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [detailsData, setDetailsData] = React.useState([])
    const [checkedValue, setCheckedValue] = React.useState("Report Data")
    const [graphType, setGraphType] = React.useState({
        barGraphClass: "iconBtnLeft active",
        lineGraphClass: "iconBtnRight",
        type: 'Bar'
    })
    let idToPrint = []
    let dataValue = {}
    let options = {}
    let reportWidgetName=sessionStorage.getItem("customerType")==="SWP"?"Water Utilization Metrics":"Agri Process Value Addition";
    const [parametersList, setParametersList] = React.useState(
        [{ value: "All", selected: false, disabled: false },
        { value: "Impact Metrics", selected: false, disabled: false },
        { value: "Capacity Utilization", selected: false, disabled: false },
        { value: "Income Generation", selected: false, disabled: false },
        { value: "Environment Contribution", selected: false, disabled: false },
        { value: "Agri Process Value Addition", selected: false, disabled: false },
        { value: "Installation", selected: false, disabled: false },
        { value: "Energy Consumption", selected: false, disabled: false },
        ])
    let chooseData = [{ value: "Report Data", checked: true }, { value: "Raw Data", checked: true }]
    const [periodSelectedData, setPeriodSelectedData] = React.useState({})
    const [jsonToPost,setjsonToPost] = React.useState({
        "reportAPI": "saveReportData",
        "reportParam": sessionStorage.getItem("userId"),
        "reportSite": "",
        "reportCustomer": sessionStorage.getItem("customerId"),
        "reportUserid": sessionStorage.getItem("userId"),
        "customerType": sessionStorage.getItem("customerType"),
        "location": "",
        "customerName": "",
        "reportDataList": []
    })
    const fetchAgroSiteList = () => {
        authCall.getAgroSiteList(sessionStorage.getItem("userId"), sessionStorage.getItem("customerType")).then(entries => {
            entries.unshift({ "SiteName": "Choose location", "NoOfCustomers": null })
            setLocation(entries)
        }).catch(error => {
            console.log(error);
        });
        // console.log(parametersList)
        // let i=parametersList.findIndex(obj=>obj.value==="Agri Process Value Addition")
        // parametersList[i].value=reportWidgetName
        // console.log(parametersList[i])
    }
    const fetchCustomerDetails = () => {
        authCall.getAgroCustomersList(sessionStorage.getItem("userId"), sessionStorage.getItem("customerType")).then(entries => {
            entries=entries.filter((item) => item.installedDate !== null);
            setCustomer(entries)
        }).catch(error => {
            console.log(error);
        });
    }
    const onSiteChange = (e) => {
        console.log(e.target.value)
        if (e.target.value !== 'Choose location') {
            setjsonToPost({... jsonToPost, location : e.target.value,reportSite : e.target.value})
           
        } else {
            setjsonToPost({... jsonToPost, location : "",reportSite : ""})
        }
        setInitialLocation(e.target.value)
        nextValidationBtn();
        let finallCustomerList = [{ "installedDate": null, "customerId": null, "Customers": "Choose customer", city: null, state: null, location: null }]
        customer.map(cust => {
            if (cust.location) {
                if ((e.target.value).toLowerCase() === (cust.location).toLowerCase()) {
                    finallCustomerList.push(cust)
                }
            } else {
            }
            setCustomerList(finallCustomerList)
        })
    }
    const onCustomerChange = (e) => {
        if (e.target.value !== 'Choose customer') {
            //jsonToPost.customerName = e.target.value
            setjsonToPost({... jsonToPost, customerName : e.target.value})
        } else {
            //jsonToPost.customerName = ""
            setjsonToPost({... jsonToPost, customerName : ""})
        }
        nextValidationBtn();
        setInitialCustomer(e.target.value)
        const InstDate = customer.map(ele => {
            if (e.target.value === (ele.Customers).trim()) {
                setInstalltionDate(ele.installedDate)
                setSelectedCustomerId(ele.customerId)
                console.log(jsonToPost)
            }
        })

    }
    const chooseLocation = location.map(ele => {
        return <option value={ele.SiteName}>{ele.SiteName}</option>
    })
    const chooseCustomer = customerList.map(ele => {
        return <option>{ele.Customers}</option>
    })
    const nextValidationBtn = () => {
        if (jsonToPost.location !== '' && jsonToPost.customerName !== '') {
            return false
        } else {
            return true
        }
    }
    const createValidationBtn = () => {
        let list = parametersList.slice(1, parametersList.length)
        if (_.find(list, ['selected', true])) {
            return false
        } else if (checkedValue === "Raw Data") {
            return false
        } else {
            return true
        }
    }
    const navigateToReportPage = () => {
        let { history } = props;
        history.push({
            pathname: '/reports'
        });
    }
    const onPeriodChange = (startDate, endDate, type) => {
        let obj = {
            startDate: startDate,
            endDate: endDate,
            type: type
        }
        setPeriodSelectedData(obj)
        if (type === "Daily" || type === "Weekly") {
            let finalList = _.filter(parametersList, (ele) => {
                if (ele.value === "Capacity Utilization" || ele.value === "All") {
                    ele.disabled = true
                    ele.selected = false
                } else {
                    ele.disabled = false
                }
                return ele
            })
            setParametersList(finalList)
        } else {
            let finalList = _.filter(parametersList, (ele) => {
                ele.disabled = false
                return ele
            })
            setParametersList(finalList)
        }

    }
    const onParametersClick = (e) => {
        //setIsLoading(true)
        if (e.target.disabled === false) {
            if (e.target.label === "All" && e.target.selected === true) {
                _.map(parametersList, ele => {
                    ele.selected = true
                })
                let updatedParametersList = parametersList.slice(0, parametersList.length)
                setParametersList(updatedParametersList)
            }
            if (e.target.label === "All" && e.target.selected === false) {
                _.map(parametersList, ele => {
                    ele.selected = false
                })
                let updatedParametersList = parametersList.slice(0, parametersList.length)
                setParametersList(updatedParametersList)
            }

            if (e.target.label !== "All" && e.target.selected === true) {
                _.map(parametersList, ele => {
                    if (ele.value === e.target.label) {
                        ele.selected = true
                    }
                })
                let updatedParametersList = parametersList.slice(0, parametersList.length)
                setParametersList(updatedParametersList)
            }

            if (e.target.label !== "All" && e.target.selected === false) {
                _.map(parametersList, ele => {
                    if (ele.value === e.target.label) {
                        ele.selected = false
                    }
                    if (ele.value === "All") {
                        ele.selected = false
                    }
                })
                let updatedParametersList = parametersList.slice(0, parametersList.length)
                setParametersList(updatedParametersList)
            }
            let list = parametersList.slice(1, parametersList.length)
            if (_.every(list, ['selected', true])) {
                if (parametersList[0].value === "All") {
                    parametersList[0].selected = true
                }
            }
        }
        
        //getDetails() // get details for each widget
    }
    const getDetails = () => {
        setIsLoading(true)
        let listArray = []
        _.map(parametersList, (ele) => {
            if (ele.value !== "All" && ele.selected === true) {
                listArray.push(ele)
            }
        })
        if (listArray.length > 0) {
            setIsLoading(true)
            const promises = _.map(listArray, (ele) => {
                let objToPost = {
                    "userId": jsonToPost.reportUserid,
                    "type": ele.value,
                    "granularity": periodSelectedData.type,
                    "startDate": periodSelectedData.startDate,
                    "endDate": periodSelectedData.endDate,
                    "siteName": jsonToPost.location,
                    "customerId": setCustomerId,
                    "customerType": sessionStorage.getItem("customerType")
                }
                return authCall.postAgroDashboardDetails(objToPost).then(json => {
                    if (json) {

                        return json
                    }
                })
            })
            Promise.all(promises).then(results => {
                if (results) {
                    setIsLoading(false)
                    setDetailsData(results)
                }
            })
        } else {
            setIsLoading(false)
        }
    }
    const onDataRadioChange = (ele) => {
        setCheckedValue(ele.value)
        if (ele.value === "Raw Data") {
            let finalList = _.filter(parametersList, (ele) => {
                ele.disabled = true
                ele.selected = false
                return ele
            })
            setParametersList(finalList)
        } else {
            if (periodSelectedData.type === "Daily" || periodSelectedData.type === "Weekly") {
                let finalList = _.filter(parametersList, (ele) => {
                    if (ele.value === "Capacity Utilization" || ele.value === "All") {
                        ele.disabled = true
                        ele.selected = false
                    } else {
                        ele.disabled = false
                    }
                    return ele
                })
                setParametersList(finalList)
            } else {
                let finalList = _.filter(parametersList, (ele) => {
                    ele.disabled = false
                    return ele
                })
                setParametersList(finalList)
            }
        }

    }
    const renderParameters = parametersList.map(ele => {
        return <div style={{ display: 'block', padding: '5px 0 0 5px' }}>
            <se-checkbox label={ele.value} disabled={ele.disabled} selected={ele.selected} onClick={onParametersClick}></se-checkbox>
        </div>
    })
    const renderChooseData = chooseData.map(ele => {
        return <div className="font-normal mt-2 col-md-3">
            <input checked={checkedValue === ele.value} onClick={() => onDataRadioChange(ele)} type="radio" value={ele.value} /> {ele.value}</div>
    })
    const formJsonToPost = (idData, ele) => {
        setIsLoading(true)
        jsonToPost.reportDataList.push(
            {
                "reportType": periodSelectedData.type,
                "reporWidgetName": ele.value,
                "fromDate": periodSelectedData.startDate,
                "toDate": periodSelectedData.endDate,
                "reportTableData": idData
            }
        )
    }
    const formIdData = (ele) => {
        setIsLoading(true)
        let idData = []
        _.map(idToPrint, eachId => {
            if (eachId.startsWith(ele.value + 'Graph') === true) {
                idData.push({ chart: document.getElementById(eachId).toDataURL('image/png') })
            } else if (eachId.startsWith(ele.value + 'Table') === true) {
                idData.push({ tableData: document.getElementById(eachId).innerHTML })
            }
        })
        formJsonToPost(idData, ele)
    }
    const createReport = () => {
        
        let wparmsList=parametersList.filter(e=>e.selected===true);
        // console.log(parametersList,wparmsList)
        // console.log(jsonToPost,jsonToPost.location)
        
        setIsLoading(true)
        if (checkedValue === "Raw Data") {
            let jsonToPost1 = {
                "userId": sessionStorage.getItem("userId"),
                "granularity": periodSelectedData.type,
                "startDate": periodSelectedData.startDate,
                "endDate": periodSelectedData.endDate,
                "siteName": jsonToPost.location,
                "customerId": setCustomerId,
                "customerType": sessionStorage.getItem("customerType")
            }
            authCall.postGenerateRawData(jsonToPost1).then(json => {
                if (json) {
                    let a = document.createElement("a")
                    a.href = json
                    a.target= '_blank'
                    a.click()
                    setIsLoading(false)
                    let { history } = props;
                    history.push({
                        pathname: '/reports'
                    });
                }
            })
        } else {
            let jsonToPost1 = {
                "userId": sessionStorage.getItem("userId"),
                "granularity": periodSelectedData.type,
                "startDate": periodSelectedData.startDate,
                "endDate": periodSelectedData.endDate,
                "siteName": jsonToPost.location,
                "customerId": setCustomerId,
                "customerType": sessionStorage.getItem("customerType"),
                "ParamList":wparmsList
            }
            authCall.postGeneratePdfReport(jsonToPost1).then(json => {
                if (json) {
                    let a = document.createElement("a")
                    a.href = json
                    a.target= '_blank'
                    a.click()
                    setIsLoading(false)
                    let { history } = props;
                    history.push({
                        pathname: '/reports'
                    });
                }
                else{
                    setIsLoading(false)
                    document.getElementById('admin-snackbar').open = true;
                    setSnackBarObj({ type: "warning", msg: "Failed to generate PDF" })
                }
            })

            // jsonToPost.reportDataList = []
            // let listArray = []
            // let idData = []
            // _.map(parametersList, (ele) => {
            //     if (ele.value !== "All" && ele.selected === true) {
            //         listArray.push(ele)
            //     }
            // })
            // let firmwareTable = !_.isNull(document.getElementById('InstallationInfoTablePng1234')) ? document.getElementById('InstallationInfoTablePng1234') : document.createElement("DIV")
            // let motorTable = !_.isNull(document.getElementById('InstallationMotorInfoTablePng1234')) ? document.getElementById('InstallationMotorInfoTablePng1234') : document.createElement("DIV")
            // let realTimeEnergyTable = !_.isNull(document.getElementById('Energy ConsumptionInfoTablePng1234')) ? document.getElementById('Energy ConsumptionInfoTablePng1234') : document.createElement("DIV")
            // let serachString = "summary"
            // let domtoImageID = _.filter(idToPrint, function (o) { return o.toLowerCase().indexOf(serachString.toLowerCase()) !== -1 })
            // domtoimage.toPng(document.getElementById(domtoImageID[0]) ? document.getElementById(domtoImageID[0]) : document.createElement("DIV")).then(function (dataURL) {
            //     domtoimage.toPng(document.getElementById(domtoImageID[1]) ? document.getElementById(domtoImageID[1]) : document.createElement("DIV")).then(function (dataURL2) {
            //         domtoimage.toPng(firmwareTable).then(dataFirmwareURL => {
            //             domtoimage.toPng(realTimeEnergyTable).then(dataRealTimeEnergyTableURL => {
            //                 domtoimage.toPng(motorTable).then(motorTableURL => {
            //                     _.map(listArray, ele => {
            //                         switch (ele.value) {
            //                             case 'Impact Metrics':
            //                                 formIdData(ele)
            //                                 break;
            //                             case reportWidgetName:
            //                                 formIdData(ele)
            //                                 break;
            //                             case 'Income Generation':
            //                                 formIdData(ele)
            //                                 break;
            //                             case 'Environment Contribution':
            //                                 formIdData(ele)
            //                                 break;
            //                             case 'Installation':
            //                                 idData = []
            //                                 _.map(idToPrint, eachId => {
            //                                     if (eachId.startsWith(ele.value + 'Graph') === true) {
            //                                         idData.push({ chart: document.getElementById(eachId).toDataURL('image/png') })
            //                                     } else if (eachId.startsWith(ele.value + 'Table') === true) {
            //                                         idData.push({ tableData: document.getElementById(eachId).innerHTML })
            //                                     }
            //                                 })
            //                                 let tableImage = new Image();
            //                                 if (dataFirmwareURL) {
            //                                     dataFirmwareURL === "data:," ? tableImage = "" : tableImage = dataFirmwareURL
            //                                     idData.push({ chart: tableImage })
            //                                 }
            //                                 if (motorTableURL) {
            //                                     motorTableURL === "data:," ? tableImage = "" : tableImage = motorTableURL
            //                                     idData.push({ chart: tableImage })
            //                                 }
            //                                 formJsonToPost(idData, ele)
            //                                 break;
            //                             case 'Energy Consumption':
            //                                 idData = []
            //                                 _.map(idToPrint, eachId => {
            //                                     if (eachId.startsWith(ele.value + 'Graph') === true) {
            //                                         idData.push({ chart: document.getElementById(eachId).toDataURL('image/png') })
            //                                     } else if (eachId.startsWith(ele.value + 'Table') === true) {
            //                                         idData.push({ tableData: document.getElementById(eachId).innerHTML })
            //                                     }
            //                                 })
            //                                 let tableImage2 = new Image();
            //                                 if (dataRealTimeEnergyTableURL) {
            //                                     dataRealTimeEnergyTableURL === "data:," ? tableImage2 = "" : tableImage2 = dataRealTimeEnergyTableURL
            //                                     idData.push({ chart: tableImage2 })
            //                                 }
            //                                 formJsonToPost(idData, ele)
            //                                 break;
            //                             case 'Capacity Utilization':
            //                                 idData = []
            //                                 _.map(domtoImageID, (eachDomToImage, index) => {
            //                                     if (index === 0) {
            //                                         idData.push({ chart: dataURL })

            //                                     } else {
            //                                         idData.push({ chart: dataURL2 })
            //                                     }
            //                                     idData.push({ chart: document.getElementById(ele.value + 'Graph1234' + index).toDataURL('image/png') })
            //                                     idData.push({ tableData: document.getElementById(ele.value + 'Table1234' + index).innerHTML })
            //                                 })
            //                                 formJsonToPost(idData, ele)
            //                                 break;
            //                         }
            //                     })
            //                     authCall.postGeneratePdfReport(jsonToPost).then(json => {
            //                         if (json) {
            //                             let a = document.createElement("a")
            //                             a.href = json
            //                             a.click()
            //                             setIsLoading(false)
            //                             let { history } = props;
            //                             history.push({
            //                                 pathname: '/reports'
            //                             });
            //                         }
            //                     })
            //                 })
            //             })
            //         })
            //     })
            // })
        }

    }
    React.useEffect(() => {
        if (location.length === 0) {
            fetchAgroSiteList()
        }
        if (customer.length === 0) {
            fetchCustomerDetails()
        }

    }, [location, customer])
    return <se-container class="grey-backgroundCreate" >
        <se-container position="absolute" direction="column" style={{ overflow: 'hidden' }}>
            <se-block-header class="headerColor"><span class="font-size-22">Create Report</span>
                <div slot="end"><se-icon option="" class="pointer" onClick={navigateToReportPage}>action_close</se-icon>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </se-block-header>
            <div>
                <se-container option="centered">
                    <se-stepper id="se-stepper1" linear='true'>
                        <se-stepper-item label="Location" >
                            <se-container option="centered">
                                <div class="row px-4 pt-2 pb-2" >
                                    <se-block option="widget">
                                        <div class="border-bottom pop-window-height capacityDivHeight">
                                            <se-block-content>
                                                <div className="create-report">
                                                    <se-form-field id="select-form" labelWidth="45%" label="Location" placeholder="Choose location" padding="medium" type="select">
                                                        <select onChange={(e) => onSiteChange(e)} value={initialLocation}>
                                                            {chooseLocation}
                                                        </select>
                                                    </se-form-field>
                                                    <se-form-field id="select-form" labelWidth="45%" label="Customer" placeholder="Choose customer" padding="medium" type="select">
                                                        <select onChange={(e) => onCustomerChange(e)} value={initialCustomer}>
                                                            {chooseCustomer}
                                                        </select>
                                                    </se-form-field>
                                                </div>
                                            </se-block-content>
                                        </div>
                                        <se-block-footer>
                                            <div class="col-md-6 pl-2 pt-2"></div>
                                            <div class="col-md-6 text-right">
                                                <se-button onclick="document.getElementById('se-stepper1').next(true);" disabled={nextValidationBtn()}>Next</se-button>
                                            </div>
                                        </se-block-footer>
                                    </se-block>
                                </div>
                            </se-container>
                        </se-stepper-item>
                        <se-stepper-item label="Period" >
                            <se-container option="centered" >
                                <div class="row px-4 pt-2 pb-2" >
                                    <se-block option="widget">
                                        <div class="border-bottom-overview capacityDivHeight pop-window-height report-period-alignment">
                                            <se-block-content>
                                                <div style={{ marginTop: "1rem" }}></div>
                                                <ChoosePeriod onChange={onPeriodChange} InstaltedDate={installtionDate} />
                                            </se-block-content>
                                        </div>
                                        <se-block-footer>
                                            <div class="col-md-6 pl-2 pt-2"></div>
                                            <div class="col-md-6 text-right">
                                                <se-button onclick="document.getElementById('se-stepper1').previous(true);">Previous</se-button>
                                                <se-button onclick="document.getElementById('se-stepper1').next(true);">Next</se-button>
                                            </div>
                                        </se-block-footer>
                                    </se-block>
                                </div>
                            </se-container>
                        </se-stepper-item>
                        <se-stepper-item label="Parameters" >
                            <se-container option="centered" style={{ overflow: 'hidden' }}>
                                <div class="row px-4 pt-2 pb-2" >
                                    <se-block option="widget">
                                        {isLoading ? <se-loading loading="true"></se-loading> : null}
                                        <div class="border-bottom-overview capacityDivHeight pop-window-height report-period-alignment">
                                            <se-block-content>
                                                <div className="font-size-22 mt-3">Choose parameters</div>
                                                <div className="row">
                                                    {renderChooseData}
                                                </div>
                                                {renderParameters}
                                            </se-block-content>
                                        </div>
                                        <se-block-footer>
                                            <div class="col-md-6 pl-2 pt-2"></div>
                                            <div class="col-md-6 text-right">
                                                <se-button onclick="document.getElementById('se-stepper1').previous(true);">Previous</se-button>
                                                <se-button onClick={createReport} disabled={createValidationBtn()}>Create</se-button>
                                            </div>
                                        </se-block-footer>
                                        <div style={{ position: "absolute", zIndex: -1, top: "10px", width: "95%" }}>
                                            {_.map(detailsData, eachElem => {
                                                if (eachElem.widgetType === "Impact Metrics" ||
                                                    eachElem.widgetType === reportWidgetName ||
                                                    eachElem.widgetType === "Environment Contribution") {
                                                    const { renderGraphDetails } = useImpactMetricsGraphDetails(eachElem, graphType, idToPrint, dataValue, options, eachElem.widgetType)
                                                    return renderGraphDetails()
                                                } else if (eachElem.widgetType === "Installation") {
                                                    const { renderInfoTable, renderMotorInfoTable, renderGraphDetails1, renderGraphDetails2 } = useImpactMetricsGraphDetails(eachElem, graphType, idToPrint, dataValue, options, eachElem.widgetType)
                                                    return <div>
                                                        {renderGraphDetails1()}
                                                        {renderGraphDetails2()}
                                                        {renderInfoTable()}
                                                        {renderMotorInfoTable()}
                                                    </div>
                                                } else if (eachElem.widgetType === "Capacity Utilization") {
                                                    const { renderCapacityGraphDetails } = useImpactMetricsGraphDetails(eachElem, graphType, idToPrint, dataValue, options, eachElem.widgetType)
                                                    return renderCapacityGraphDetails()
                                                } else if (eachElem.widgetType === "Income Generation") {
                                                    const { renderIncomeGraphDetails } = useImpactMetricsGraphDetails(eachElem, graphType, idToPrint, dataValue, options, eachElem.widgetType)
                                                    return renderIncomeGraphDetails()
                                                } else if (eachElem.widgetType === "Energy Consumption") {
                                                    const { renderRealtimeGraphDetails, renderRealtimeInfoTable } = useImpactMetricsGraphDetails(eachElem, graphType, idToPrint, dataValue, options, eachElem.widgetType)
                                                    return <div>
                                                        {renderRealtimeGraphDetails()}
                                                        <div style={{ width: "90%" }}>
                                                            {renderRealtimeInfoTable()}
                                                        </div>
                                                    </div>
                                                }
                                            })}
                                        </div>
                                    </se-block>
                                </div>
                            </se-container>
                        </se-stepper-item>
                    </se-stepper>
                </se-container>
            </div>
        </se-container>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container>
}
export default CreateReport