import React, { Component } from 'react';

class UnAuthorized extends Component {
  render() {
    return (
      <div style={{textAlign:"center",paddingTop:"35vh"}}>
        <h1 className="title">UnAuthorized</h1>
        <p className="notification is-info">
          Please wait until admin provide access to you!!
        </p>
      </div>
    );
  }
}

export default UnAuthorized;
