import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ShellLayout from './layouts/shell';
import DefaultLayout from './layouts/default';
import InActiveUserLayout from './layouts/inactiveuser'

import Login from './pages/login';
import NoPage from './pages/404';
import MyCustomers from './pages/mycustomers';
import OverView from './pages/overview';
import Notifications from './pages/notifications';
import Reports from './pages/reports';
import Settings from './pages/settings';
import SignUp from './pages/signup';
import MyProfile from './pages/myprofile';
import CreateNewUser from './pages/createnewuser';
import UtilizedDetails from './pages/utilizedDetails';
import CapacityUtilizationDetails from './pages/capacityUtilizationDetails';
import ImpactMetricsDetails from './pages/impactMetricsDetails';
import IncomeGenerationDetails from './pages/incomeGenerationDetails';
import AgriproducedDetails from './pages/agriproducedDetails';
import InstallationDetails from './pages/installationDetails';
import EnvironmentDetails from './pages/EnvironmentDetails';
import CreateReport from './pages/createReport';
import AdminConsole from './pages/adminConsole';
import AdminPage from './pages/admin';
import LocalMap from './pages/localMap'
import RealtimeMonitoringDetails from './pages/realtimeMonitoringDetails';
import gallery from './pages/gallery';
import LandingPage from './pages/Landing';
import UserManagement from './pages/usermanagment';
import { AuthProvider } from './services/authProvider';
import Authenticate from 'react-openidconnect';
import OidcSettings from './auth/oidcsettings';
import { Callback } from './services/callback';
import { LogoutCallback } from './services/logoutCallback';
import { Logout } from './services/logout';
import AuthService from './services/AuthService';
import { AuthSignInPage } from './services/AuthSign';
import UnAuthorized from './pages/unauthorized';

const authLogin = new AuthService(); 

const AppRoute = ({ component: Component, layout: Layout,auth:AuthService, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)


const App = () => {
  const [currentDate, setCurrentDate] = React.useState("")
  const [user, setuser] = React.useState({})
  const getLastRefreshData = (callback) => {
    setCurrentDate(callback)
  }

  return ( 
  <div>
    
    <AuthProvider>
    <se-app>
      <BrowserRouter>
        <Switch>
          {/* <Route exact path="/" render={props => (<Login {...props} />)} /> */}
          {/* <Route exact path="/login" render={props => (<Login {...props} />)} /> */}
          <AppRoute path="/login/callback" layout={DefaultLayout} component={Callback} />
          <AppRoute path="/logout/callback" layout={DefaultLayout} component={LogoutCallback} />
          <Route exact={true} path="/logout" layout={DefaultLayout} component={Logout} />          
          <AppRoute path="/unauthorized" layout={InActiveUserLayout} component={UnAuthorized} />
          <Route exact path="/" render={props => (<AuthSignInPage {...props} />)} />
          <ShellLayout dateValue={currentDate}>
            <Route path="/overview" component={() => <OverView onChange={getLastRefreshData} />} />
            <Route path="/notifications" component={() => <Notifications onChange={getLastRefreshData} />} />
            <Route path="/mycustomers" component={() => <MyCustomers onChange={getLastRefreshData} />} />
            <Route path="/reports" component={() => <Reports onChange={getLastRefreshData} />} />
            <Route path="/settings" component={Settings} />
            <Route path="/signup" component={SignUp} />
            <Route path="/myprofile" component={MyProfile} />
            <Route path="/createnewuser" component={CreateNewUser} />
            <Route path="/createReport" component={CreateReport} />
            <Route path="/utilizedDetails" component={UtilizedDetails} />
            <Route path="/capacityUtilizationDetails" component={CapacityUtilizationDetails} />
            <Route path="/impactMetricsDetails" component={ImpactMetricsDetails} />
            <Route path="/incomeGenerationDetails" component={IncomeGenerationDetails} />
            <Route path="/agriproducedDetails" component={AgriproducedDetails} />
            <Route path="/installationDetails" component={InstallationDetails} />
            <Route path="/environmentDetails" component={EnvironmentDetails} />
            <Route path="/dataManagement" component={AdminConsole} />
            <Route path="/energyConsumptionDetails" component={RealtimeMonitoringDetails} />
            <Route path="/admin" component={AdminPage} />
            <Route path="/gallery" component={gallery} />
            <Route path="/localMap" component={LocalMap} />
            <Route path="/Landing" component={LandingPage} />
            <Route path="/UserManagment" component={UserManagement} />

            
          </ShellLayout>
          
          <AppRoute path="/*" layout={DefaultLayout} component={NoPage} />
        </Switch>
      </BrowserRouter>
    </se-app>
    </AuthProvider>
    </div>
  );

}

export default App;
