import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'react-day-picker/lib/style.css';
import Auth from '../api/ApiSession';
import './styles.css';
import _ from 'lodash';
import ChoosePeriod from '../components/choosePeriod';
import useImpactMetricsGraphDetails from './useImpactMetricsGraphDetails';
const authCall = new Auth();
const IncomeGenerationDetails = (props) => {
    const [utilityDetails, setUtilityDetails] = React.useState({})
    const [utilityDetailsCopy, setUtilityDetailsCopy] = React.useState({})
    const [selectedCheckBox, setSelectedCheckBox] = React.useState([])
    const [snackBarObj, setSnackBarObj] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [graphType, setGraphType] = React.useState({
        barGraphClass: "iconBtnLeft active",
        lineGraphClass: "iconBtnRight",
        type: 'Bar'
    })
    const [dataToPost, setDataToPost] = React.useState({
        userId: sessionStorage.getItem("userId"),
        type: "Income Generation",
        granularity: null,
        startDate: null,
        endDate: null,
        siteName: sessionStorage.getItem("siteName"),
        customerId: parseInt(sessionStorage.getItem("customerId")),
        customerType: sessionStorage.getItem("customerType"),
        graphType:graphType.type
    })
    const intervalRef = React.useRef()
    let idToPrint = []
    let dataValue = {}
    let options = {}
    const onPeriodChange = (startDate, endDate, type) => {
        let dataTopost = dataToPost
        dataTopost.startDate = startDate
        dataTopost.endDate = endDate
        dataTopost.granularity = type
        setDataToPost(dataTopost)
        fetchUtilityDetails('normalCall')
    }
    const { renderIncomeGraphDetails } = useImpactMetricsGraphDetails(utilityDetails, graphType, idToPrint, dataValue, options, utilityDetails.widgetType)
    const fetchUtilityDetails = (call) => {
        call === "normalCall" ? setIsLoading(true) : setIsLoading(false);
        authCall.postAgroDashboardDetails(dataToPost).then(json => {
            if (json) {
                //    let json = {
                //         "widgetType": "Income Generation",
                //         "lastUpdated": "4 days ago-12:21 26-11-2020", "performance": "Above Target",
                //         "installationDate": "2020-07-12", "type": "Daily Breakup of Energy Utilized",
                //         "widgetData": [{ propName: "Rice Huller", propVal: null, units: null, propColor: null, propIcon: null },
                //         { propName: "Paddy Thresher", propVal: null, units: null, propColor: null, propIcon: null },
                //         { propName: "Sugarcane Thresher", propVal: null, units: null, propColor: null, propIcon: null },
                //         { propName: "Pump", propVal: null, units: null, propColor: null, propIcon: null },
                //         { propName: "Income", propVal: "1.33", units: "INR", propColor: null, propIcon: "money" }],
                //         "periods": ["01-11", "02-11", "03-11", "04-11", "05-11", "06-11", "07-11", "08-11", "09-11", "10-11", "11-11", "12-11", "13-11", "14-11", "15-11", "16-11", "17-11", "18-11", "19-11", "20-11", "21-11", "22-11", "23-11", "24-11", "25-11", "26-11", "27-11", "28-11", "29-11", "30-11"],
                //         "tableData": null,
                //         "widgetDetails": [
                //             {
                //                 "XUnits": "Period (Days)",
                //                 "YUnits": "Rupees",
                //                 "graphType": "Doughnut",
                //                 "graphData": [{
                //                     "propName": "Rice Huller",
                //                     "propValue": ["254.12"],
                //                     "propLable": "Rice Huller",
                //                     "propColor": "#219bfd",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 },
                //                 {
                //                     "propName": "Paddy Thresher",
                //                     "propValue": ["260.73"],
                //                     "propLable": "Paddy Thresher",
                //                     "propColor": "#626469",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 },
                //                 {
                //                     "propName": "Sugarcane Thresher",
                //                     "propValue": ["260.72"],
                //                     "propLable": "Sugarcane Thresher",
                //                     "propColor": "#ffd100",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 },
                //                 {
                //                     "propName": "Pump",
                //                     "propValue": ["254.2"],
                //                     "propLable": "Pump",
                //                     "propColor": "#e47f00",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 }]
                //             },
                //             {
                //                 "XUnits": "Period (Days)",
                //                 "YUnits": "Rupees",
                //                 "graphType": null,
                //                 "graphData": [{
                //                     "propName": "Rice Huller",
                //                     "propValue": [0.0, 0.0, 0.0, 0.0, 17.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 14.0, 0.0, 0.0, 0.0, 0.0],
                //                     "propLable": "Rice Huller",
                //                     "propColor": "#219bfd",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 },
                //                 {
                //                     "propName": "Paddy Thresher",
                //                     "propValue": [0.0, 0.0, 0.0, 0.0, 17.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 14.0, 0.0, 0.0, 0.0, 0.0],
                //                     "propLable": "Paddy Thresher",
                //                     "propColor": "#626469",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 },
                //                 {
                //                     "propName": "Sugarcane Thresher",
                //                     "propValue": [0.0, 0.0, 0.0, 0.0, 17.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 14.0, 0.0, 0.0, 0.0, 0.0],
                //                     "propLable": "Sugarcane Thresher",
                //                     "propColor": "#ffd100",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 },
                //                 {
                //                     "propName": "Pump",
                //                     "propValue": [0.0, 0.0, 0.0, 0.0, 17.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 14.0, 0.0, 0.0, 0.0, 0.0],
                //                     "propLable": "Pump",
                //                     "propColor": "#e47f00",
                //                     "propTitle": null,
                //                     "propSubTitle": null
                //                 }]
                //             },
                //         ]
                //     }             
                setIsLoading(false);
                let jsonCopy = JSON.parse(JSON.stringify(json));
                removeDataFromGraph(jsonCopy)
                setUtilityDetailsCopy(json)
            }
        })
    }
    React.useEffect(() => {
        intervalRef.current = setInterval(() => {
            fetchUtilityDetails('initialCall')
        }, 900000);
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])
    const removeDataFromGraph = (jsonCopy) => {
        _.map(selectedCheckBox, ele => {
            _.map(jsonCopy.widgetDetails, widgetDetail => {
                let i = 0;
                while (i < widgetDetail.graphData.length) {
                    if (widgetDetail.graphData[i].propName === ele) {
                        widgetDetail.graphData.splice(i, 1)
                        i = 0;
                    } else {
                        i++;
                    }
                }
            })
        })
        setUtilityDetails(jsonCopy)
    }
    const changeToBarGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft active",
            lineGraphClass: "iconBtnRight",
            type: "Bar"
        })
        dataToPost.graphType="bar"
    }
    const changeToLineGraph = () => {
        setGraphType({
            barGraphClass: "iconBtnLeft",
            lineGraphClass: "iconBtnRight active",
            type: "Line"
        })
        dataToPost.graphType="Line"
    }
    const navigateToOverview = () => {
        let { history } = props;
        history.push({
            pathname: '/overview'
        });

    }
    const onCheckBoxClicked = (selected, selectedData) => {
        let utilityDetails1 = JSON.parse(JSON.stringify(utilityDetailsCopy));
        if (selected === false) {
            if (selectedCheckBox.indexOf(selectedData.propName) === -1) {
                selectedCheckBox.push(selectedData.propName)
            }
        } else {
            if (selectedCheckBox.indexOf(selectedData.propName) !== -1) {
                selectedCheckBox.splice(selectedCheckBox.indexOf(selectedData.propName), 1)
            }
        }
        removeDataFromGraph(utilityDetails1)
    }
    const generateReport = () => {
        setIsLoading(true)
        // let idData = []
        // _.map(idToPrint, eachId => {
        //     if (eachId.startsWith('Income GenerationGraph') === true) {
        //         idData.push({ chart: document.getElementById(eachId).toDataURL('image/png') })
        //     } else {
        //         idData.push({ tableData: document.getElementById(eachId).innerHTML })
        //     }
        // })
        // let selectedSite;
        // if (sessionStorage.getItem("siteName") === "") {
        //     selectedSite = "All Sites"
        // } else {
        //     selectedSite = sessionStorage.getItem("siteName")
        // }
        // let jsonToPost = {
        //     "reportAPI": "saveReportData",
        //     "reportParam": sessionStorage.getItem("userId"),
        //     "reportSite": selectedSite,
        //     "reportCustomer": sessionStorage.getItem("customerId"),
        //     "reportUserid": sessionStorage.getItem("userId"),
        //     "customerType": sessionStorage.getItem("customerType"),
        //     "reportDataList": [
        //         {
        //             "reportType": dataToPost.granularity,
        //             "reporWidgetName": "Income Generation",
        //             "fromDate": dataToPost.startDate,
        //             "toDate": dataToPost.endDate,
        //             "reportTableData": idData
        //         }
        //     ]
        // }
        authCall.postGeneratePdfReport(dataToPost).then(json => {
            if (json) {
                setIsLoading(false)
                let a = document.createElement("a")                
                a.href =json.includes("\"https://")? "https://"+json.replace("\"https://",'').replace('"',''):json
                a.click()

            }else{
                setIsLoading(false)
                document.getElementById('admin-snackbar').open = true;
                setSnackBarObj({ type: "warning", msg: "Failed to generate PDF" })
            }
        })
    }
    const getPropValue = () => {
        let jsonCopy = JSON.parse(JSON.stringify(utilityDetails.widgetData))
        _.map(selectedCheckBox, ele => {
            _.map(jsonCopy,widgetData=>{
                if(widgetData.propName===ele){
                    widgetData.propVal="0.0"
                }
            })
        })     
        let count = 0;
        _.map(jsonCopy, widgetData => {
            if(widgetData.propName!=="Income"){
                count = count + parseFloat(widgetData.propVal)
            }
        })
        if(count===0.00){
            return ""
         }else{
             return count.toFixed(1)
         } 
    }
    const renderCheckBoxList = _.map(utilityDetails.widgetData, (ele, index) => {
        if(sessionStorage.getItem("customerType")==="SWP"){
            return <div key={index} class="row align-items-center detailscheckBlock">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
                    <se-icon size="large" color="standard" style={{fontSize:"40px"}}>{ele.propIcon}</se-icon>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-4 col-xl-3 px-0 textLineHight pt-2 text-left">
                    <span class="font-size-12 textColor">{ele.propName}</span>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-5 px-0 text-right">
                    <span class="font-size-37" style={{ position: 'relative' }}>{ele.propVal} 
                    {/* <span className={'displayUnits'}>{ele.units}</span> */}
                        </span> 
                        <div class="font-size-12 font-weight-600" style={{marginTop:"-2vh"}}>{ele.units}</div>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2" style={{padding:"15px"}}>
                    <se-checkbox selected="true" onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}></se-checkbox>
                </div>
            </div>
        }
        else if (ele.propIcon) {
            return <div key={index} class="row align-items-center">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 px-0 text-center">
                    <se-icon size="large" color="standard" style={{fontSize:"40px"}}>{ele.propIcon}</se-icon>
                    <span class="font-size-12 textColor">{ele.propName}</span>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                    <span class="font-size-37" style={{ position: 'relative' }}>{getPropValue()} <span class="font-size-12 font-weight-600">{getPropValue()?ele.units:null}</span>
                        {/* <span className={'displayUnits'}>{getPropValue()?ele.units:null}</span> */}
                        </span>
                </div>
            </div>
        } else {
            return <div key={index} class="row align-items-center">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 font-normal">{ele.propName}</div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-5 px-0 text-right">
                    <se-checkbox selected="true" onClick={(e) => onCheckBoxClicked(e.target.selected, ele)}></se-checkbox>
                </div>
            </div>
        }
    })
    return <se-container position="absolute" direction="row" >
        {isLoading ? <div class="align-items-center"><se-loading loading="true"></se-loading></div> : null}
        <se-block>
            <se-block-header class="headerColor"><span class="font-size-22">
                {utilityDetails.widgetType}-{sessionStorage.getItem("siteName") !== "" ?
                    sessionStorage.getItem("siteName") : "All Sites"}</span>
                <div slot="end"><se-icon option="" class="pointer" onClick={() => navigateToOverview()}>action_close</se-icon>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </se-block-header>
            <se-block-content class="grey-backgroundCreate" >
                <se-container position="absolute" direction="row" margin="large" padding="large">
                    <div class="col-md-4 col-lg-3 col-xl-3 px-0" option="widget" margin="none" style={{background:"white"}}>
                        <div class="border-bottom divDetailsHeight text-left">
                            <span class="font-normal font-weight-400">Last updated: {utilityDetails.lastUpdated}</span>
                        </div>
                        <div class="border-bottom">
                            <se-block-content>
                                <ChoosePeriod onChange={onPeriodChange} InstaltedDate={utilityDetails.installationDate} />
                            </se-block-content>
                        </div>
                        <se-block-content class="ChooseDivHeight">
                            {/* <div class="rows">
                                <span className="font-size-22">Choose source of Income</span>
                            </div> */}
                            {renderCheckBoxList}
                        </se-block-content>
                    </div>
                    <se-divider option="vertical"></se-divider>
                    <se-block class="px-0">
                    <div class="mt-30px">
                        <div class="rows pdf-download">
                            <div className="mt-2 toggleBtn">
                                <span onClick={() => changeToBarGraph()} className={graphType.barGraphClass}>
                                    <se-icon >graph_barchart</se-icon>
                                </span>
                                <span onClick={() => changeToLineGraph()} className={graphType.lineGraphClass}>
                                    <se-icon >energy</se-icon></span>
                            </div>
                            <se-icon option="button" onClick={() => generateReport()} color="alternative" size="large">action_pdf_report</se-icon>
                        </div>
                        {renderIncomeGraphDetails()}
                        </div>
                    </se-block>                   
                </se-container>
            </se-block-content>
        </se-block>
        <div style={{ position: "absolute", left: '15px' }}>
            <se-snackbar id="admin-snackbar" duration="5000" type={snackBarObj.type}
                message={snackBarObj.msg} ></se-snackbar>
        </div>
    </se-container>
}
export default withRouter(IncomeGenerationDetails);