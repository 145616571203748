import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Auth from '../api/ApiSession'
import _ from 'lodash';
import Pagination from 'react-js-pagination';
const authCall = new Auth()
const AdminConsole = () => {
    const [tableData, setTableData] = React.useState({})
    const [checkedValue, setCheckedValue] = React.useState("1")
    const [isLoading, setIsLoading] = React.useState(false)
    const [copyTableData, setCopyTableData] = React.useState({})
    const [copyForPagination, setCopyForPagination] = React.useState({})
    const [currentPage, setCurrentPage] = React.useState(1)
    const [pageLength, setPageLength] = React.useState(0)
    const [initialLoad, setInitialLoad] = React.useState(0)
    let postPerPage = 20;
    const periodLable = [
        { "value": "4", "label": "Agro", "disabled": false },
        { "value": "5", "label": "SWP", "disabled": false },
        { "value": "6", "label": "nethope", "disabled": false },
    ]
    const fetchTableData = (id) => {
        setIsLoading(true)
        authCall.getAdminConsole(id).then(json => {
            setTableData(json)
            setIsLoading(false)
            setCopyTableData(json)
        }).catch(error => {
            console.log(error);
        });
    }
    const getHeaders = () => {
        let headerArray = []
        if (Object.keys(tableData).length > 0) {
            for (let i in tableData.RawData) {
                if (i === '0') {
                    for (let j in tableData.RawData[i]) {
                        if (j !== "devicetype") {
                            headerArray.push(j)
                        }
                    }
                    break;
                }
            }
        }
        return _.map(headerArray, ele => {
            return <th style={{
                overflow: 'hidden', minHeight: '47px', height: 'auto', position: "sticky", top: '0',
                margin: '0px', padding: '10px', border: '1px solid lightgray', background: '#ffffff'
            }}>{ele}</th>
        })
    }
    const getTableTd = (ele) => {
        return _.map(Object.keys(ele), keys => {
            if (keys !== "devicetype") {
                return <td style={{
                    overflow: 'hidden', minHeight: '47px', height: 'auto',
                    margin: '0px', padding: '10px'
                }}>{ele[keys]}</td>
            }
        })
    }
    const getTableData = () => {
        if (Object.keys(tableData).length > 0) {
            return _.map(tableData.RawData, ele => {
                return <tr> {getTableTd(ele)}  </tr>
            })
        }
    }

    const renderTable = () => {
        return <div class="col-md-12" style={{ height: '78vh', width: "78vw", overflowX: "scroll", overflowY: "scroll" }}>
            <table style={{ width: "100%" }}>
                <caption style={{ backgroundColor: 'rgb(159, 160, 164)', color: 'white', captionSide: 'top', padding: '10px' }}>
                    Data Management</caption>
            </table>
            <thead><tr>{getHeaders()} </tr> </thead>
            <tbody>{getTableData()}</tbody>
        </div>
    }
    const onRadioButtonClick = (ele) => {
        setCheckedValue(ele.value)
    }
    const renderRadioOption = periodLable.map(ele => {
        return <div key={Math.random()} className="font-normal mt-2"><input type="radio"
            disabled={ele.disabled} value={ele.value} checked={checkedValue === ele.value}
            onClick={() => onRadioButtonClick(ele)} />
            &nbsp; {ele.label} </div>
    })
    const paginate = (pageNumber) => {
        if (Object.keys(copyForPagination).length > 0) {
            setCurrentPage(pageNumber)
            let from = ((pageNumber - 1) * postPerPage);
            let to = pageNumber * postPerPage
            let copy = JSON.parse(JSON.stringify(copyForPagination))
            copy.RawData = copy.RawData.slice(from, to)
            setTableData(copy)
            setPageLength(copyForPagination.RawData.length)
        }
    }
    const initialPageLoad = (pageNumber) => {
        if (Object.keys(tableData).length > 0) {
            setCurrentPage(pageNumber)
            let from = ((pageNumber - 1) * postPerPage);
            let to = pageNumber * postPerPage
            let copy = JSON.parse(JSON.stringify(tableData))
            copy.RawData = copy.RawData.slice(from, to)
            setTableData(copy)
            setPageLength(tableData.RawData.length)
        }
    }

    if (initialLoad === 0 && Object.keys(tableData).length > 0) {
        setInitialLoad(1)
        initialPageLoad(currentPage)
        setTableData(tableData)
        setCopyForPagination(tableData)
    }

    const searchTable = (value) => {
        if (Object.keys(copyTableData).length > 0) {
            let searchString = value.toLowerCase()
            let copy = JSON.parse(JSON.stringify(copyTableData))
            let filteredData = { "RawData": [] }
            for (let i in copy.RawData) {
                if (copy.RawData[i].DeviceId.toLowerCase().indexOf(searchString) !== -1) {
                    filteredData.RawData.push(copy.RawData[i])
                }
            }
            setTableData(filteredData)
            setCopyForPagination(filteredData)
        }
    }
    const fetchData = () => {
        fetchTableData(checkedValue)
    }

    React.useEffect(() => {
        paginate(currentPage)
    }, [copyForPagination])



    return <se-container position="grid" direction="row" margin="large" padding="large">
        <se-block class="col-md-2 px-0">
            <div class="border-bottom">
                <se-block-content>
                    <div>
                        <div className="font-size-22">Choose type</div>
                        <div>
                            {renderRadioOption}
                        </div>
                    </div>
                    <se-button onClick={() => fetchData()} option="flat" color="primary">Fetch Data</se-button>
                </se-block-content>
            </div>
        </se-block>
        <se-divider option="vertical"></se-divider>
        <se-block>
            {isLoading ? <se-loading loading="true"></se-loading> : null}
            <se-block-content>
                <se-form-field label="Search">
                    <input type="text" placeholder="search by device id" onChange={(e) => searchTable(e.target.value)} />
                </se-form-field>
                {renderTable()}
                {pageLength > 0 ?
                    <div className="col-md-12" style={{ paddingTop: "10px" }}>
                        <hr></hr>
                        <Pagination class="pull-right" activePage={currentPage} itemsCountPerPage={postPerPage}
                            totalItemsCount={pageLength} onChange={(e) => paginate(e)} />
                    </div>

                    : null}
            </se-block-content>
        </se-block>

    </se-container >
}
export default withRouter(AdminConsole);