import React, { Component } from 'react';

class NoPage extends Component {
  render() {
    return (
      <div>
        <h1 className="title">PAGE 404</h1>
        <p className="notification is-info">
          LOOOOOOOOOOOOOOOOST !
        </p>
      </div>
    );
  }
}

export default NoPage;
