export const IDENTITY_CONFIG = {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URL,
    // silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
    post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
    // audience: process.env.REACT_APP_AUDIENCE,
    response_type: "code",
    // automaticSilentRenew: false,
    filterProtocolClaims: true,
    loadUserInfo: false,
    scope: 'openid profile email api'
  };
  
  export const METADATA_OIDC = {
    issuer: process.env.REACT_APP_APIROOT,
    jwks_uri: process.env.REACT_APP_APIROOT + "/services/apexrest/oidcpublickeys",
    authorization_endpoint: process.env.REACT_APP_APIROOT + "/idplogin",
    token_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/token",
    userinfo_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/userinfo",
    end_session_endpoint: process.env.REACT_APP_APIROOT + "/VFP_IDMS_IDPSloInit",
    // check_session_iframe: process.env.REACT_APP_APIROOT + "/connect/checksession",
    revocation_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/revoke",
    introspection_endpoint: process.env.REACT_APP_APIROOT + "/services/oauth2/introspect"
  };
  




// export const IDENTITY_CONFIG = {
//     authority: process.env.REACT_APP_AUTHORITY, //(string): The URL of the OIDC provider.
//     client_id: process.env.REACT_APP_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
//     redirect_uri: process.env.REACT_APP_CLIENTROOT, //The URI of your client application to receive a response from the OIDC provider.
//     login: process.env.REACT_APP_GLOBAL_AUTHORIZATION_CODE,
//     automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
//     loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
//     silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
//     post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
//     audience: "https://example.com", //is there a way to specific the audience when making the jwt
//     responseType: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
//     grantType: "password",
//     scope: "openid example.api", //(string, default: 'openid'): The scope being requested from the OIDC provider.
//     webAuthResponseType: "id_token token"
// };

// export const METADATA_OIDC = {
//     issuer: "https://identityserver",
//     jwks_uri: process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
//     authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/authorize",
//     token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
//     userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo",
//     end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/endsession",
//     check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
//     revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/revocation",
//     introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect"
// };