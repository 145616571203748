import React, { Component } from "react";
import "./widget.scss";
import { withRouter } from 'react-router-dom';
import MediumPriority from '../images/medium.png'
// import { wc } from '../utils/webComponent';
import HighPriority from '../images/high.png'

class Dashboardnotification extends Component {
  priorityIcon = (Priority) => {
    let PriorityStatus;
    if (Priority === 1) {
      PriorityStatus = <se-icon size="small" color="error">notification_critical</se-icon>
    } else if (Priority === 2) {
      PriorityStatus = <img src={MediumPriority} width="18" height="18" />
    } else if (Priority === 3) {
      PriorityStatus = <se-icon size="small" color="secondary">information_circle</se-icon>
      // PriorityStatus = <img src={MediumPriority} width="20" height="20"/>
    }
    return PriorityStatus
  }

  load = (NotificationList) => {
    return (
      NotificationList.map((d, index) => {
        let eventHeader = d.notifictaionName.concat(" at ", d.siteName)
        return (
          <ul key={index} class="notificationList">
            <li>
              <div class="row pointer" onClick={(e) => { this.userNotification(e) }}>
                {/* <div class="col-1"><img src={HighPriority} height="15px"></img></div> */}
                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1">{this.priorityIcon(d.priorityType)}</div>
                <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                  <div><span class="font-size-16 font-weight-500">{eventHeader}</span></div>
                  <div><small class="font-size-12">{d.notificationTime}</small></div>
                  {/* <div><small class="font-size-12">Creation date: {d.notificationTime}</small></div> */}
                  {/* <div><small class="font-size-12">{d.notificationDescription}</small></div> */}
                </div>
                {/* <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div> */}
              </div>
            </li>
          </ul>

          // <se-list option="headline" can-collapse="true">             
          // <se-list-item item={evenHeader} icon="notification_critical" icon-color="primary" description={d.notificationDescription}/> 
          // </se-list> 
        )
      })
    )
  }
  userNotification() {
    let { history } = this.props;
    history.push({
      pathname: '/notifications'
    });
  }
  render() {
    const { notification } = this.props;
    let NotificationList = [];
    let highPriorityType = "", informationType = "", mediumPriorityType = "", lowPriorityType = "", highPriorityCount = "", informationCount = "", mediumPriorityCount = "", lowPriorityCount = "", totalUnacknowledged = ""
    if (Object.keys(notification).length) {
      NotificationList = notification.NotificationSpecification;
      highPriorityType = notification.NotificationCount[0].notificationType;
      highPriorityCount = notification.NotificationCount[0].notificationCount;
      mediumPriorityType = notification.NotificationCount[1].notificationType;
      mediumPriorityCount = notification.NotificationCount[1].notificationCount;
      lowPriorityType = notification.NotificationCount[2].notificationType;
      lowPriorityCount = notification.NotificationCount[2].notificationCount;
      informationType = notification.NotificationCount[3].notificationType;
      informationCount = notification.NotificationCount[3].notificationCount;
      totalUnacknowledged = notification.totalUnacknowledged
    }
    


    return (
      <se-block option="card" class="mt-2 OverNotifiction" margin="none">
        {/* <div > */}
          <se-block-header class="NotificationHeight">
          <div className="mt-0 ml-1" style={{ lineHeight: "20px" }}>
            <div className="font-size-16 font-weight-600 NotificationHeaderColor">Notification Priority</div>
          </div>
            <div className="row mt-1 mr-1" style={{ lineHeight: "20px" }}>
              <div className="col-md-4 pl-1 pr-0 full-width">
                <se-icon size="small" color="error">notification_critical</se-icon>&nbsp;<span className="font-size-14" style={{ position: "relative",display:"inline",top: "2px",verticalAlign:"top" }}>{highPriorityType} &nbsp;<span className="next-line"  style={{display:'inline' }}>{highPriorityCount}</span></span>
              </div>
              <div className="col-md-4 pl-1 pr-0 full-width" >
              <img src={MediumPriority} width="18" height="18" style={{ position: "relative", top: "2px" }} />
              {/* <se-icon size="small" color="warning">notification_critical</se-icon> */}
              &nbsp;<span className="font-size-14" style={{ position: "relative",top: "2px", verticalAlign:'top' }}>{mediumPriorityType} &nbsp;<span className="next-line"  style={{display:'inline' }}>{mediumPriorityCount}</span></span>
                
              </div>
              <div className="col-md-4 pr-0 full-width">
              <se-icon size="small" color="information">information_circle</se-icon>&nbsp;<span className="font-size-14" style={{ position: "relative", top: "2px" ,verticalAlign:'top'}}>{lowPriorityType} &nbsp;<span className="next-line"  style={{display:'inline' }}>{lowPriorityCount}</span></span>
              </div>
            </div>
            {/* <div className="row mt-2 mr-1"  style={{ lineHeight: "20px" }}>
              <div className="col-md-6 pr-0 full-width">
              <se-icon size="small" color="information">information_circle</se-icon>&nbsp;<span className="font-size-14" style={{ position: "relative", top: "2px" ,verticalAlign:'top'}}>{lowPriorityType} &nbsp;<span className="next-line">{lowPriorityCount}</span></span>
              </div>
              {/* <div className="">
          <span class="lowPriority" >{lowPriorityCount}</span>&nbsp;&nbsp;<span className="font-size-14">{lowPriorityType}</span>
          </div> 
            </div> */}
          </se-block-header>
        {/* </div> */}
        <se-block-content>
          {/* <se-block> */}
          <div className="font-size-16 font-weight-600 NotificationHeaderColor">Last Events </div>
          <se-block-content option="fill">
            {this.load(NotificationList)}
          </se-block-content>
          {/* </se-block> */}
        </se-block-content>
        {/* <se-block-footer class="grey-backgroundOverview">
          <div slot="start" class="col-md-12 font-normal font-weight-600">Total unacknowledged: {totalUnacknowledged} </div>
        </se-block-footer> */}
        <se-block-footer margin="none" padding="none" class="grey-backgroundOverview" >
            <div slot="start" class="rows profile_fontsize" >
              <div class="col-10 font-normal font-weight-600 pr-0">Total Unacknowledged: {totalUnacknowledged}</div>
              <div class="font-normal font-weight-600"></div>
            </div>
          </se-block-footer>

      </se-block>

    );
  }
}

export default withRouter(Dashboardnotification);