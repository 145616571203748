import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import AuthService from '../services/AuthService';
var authService = new AuthService();

class InActiveUserLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navStatus: true,
            menuBar: true,
            headerStatus: true
        }

    }
    onMyProfile() {
        
        window.location.href = process.env.REACT_APP_PROFILE_UPDATE
    }
    onPasswordChange(){
        window.location.href = process.env.REACT_APP_PASSWORD_CHANGE
    }
    onButtonLogout() {
        authService.logout();
        sessionStorage.clear();
    }
  render() {
    if(sessionStorage.getItem("userId")===null)
        {
            sessionStorage.clear()
            let { history } = this.props;
            history.push({
                pathname: '/'
            });
        }
    return (
      <se-app>
          <se-header app-title="Energy Access Livelihood">
                        {this.state.navStatus ? <se-sidemenu id="app-sidemenu">
                     
                            <se-sidemenu-item item="Terms & Conditions">
                                <se-block>
                                    <div class="border-bottom-overview userProfileHeader1Height"></div>
                                    <div class="border-bottom-overview userProfileHeader2Height d-flex align-items-center"><se-block-header className="px-0"><span className="font-normal font-weight-600 NotificationHeaderColor pt-2">Terms & Conditions</span></se-block-header></div>
                                    <se-link url="https://www.se.com/in/en/about-us/legal/terms-of-use.jsp" option="external">Terms of use</se-link>
                                    <se-link url="https://www.se.com/in/en/about-us/legal/data-privacy.jsp" option="external">Data Privacy Statement</se-link>
                                </se-block>
                            </se-sidemenu-item>
                            {/* <se-sidemenu-item item="Support"></se-sidemenu-item> */}

                        </se-sidemenu> : null}
                        <div slot="end" style={this.state.navStatus ? {} : { display: 'none' }}>
                           <se-dropdown alignment="right">
                                <se-button slot="trigger" option="inherit" icon="user_standard" icon-color="primary">{sessionStorage.getItem("loginUserName")}</se-button>
                                <se-list option="dropdown">
                                    <se-list-item onClick={this.onMyProfile.bind(this)} item="My Profile"></se-list-item>
                                    <se-list-item onClick={this.onPasswordChange.bind(this)} item="Change Password"></se-list-item>
                                    <se-divider></se-divider>
                                    <se-list-item onClick={this.onButtonLogout.bind(this)} item="Logout"></se-list-item>
                                </se-list>
                            </se-dropdown>
                        </div>
                        {/* <div slot="end">
                            <se-button option="inherit" icon="headset" icon-color="secondary">
                                <se-link  color="secondary" option="internal">Contact Support</se-link>
                            </se-button>
                        </div> */}
                    </se-header>
        {this.props.children}
      </se-app>
    );
  }
}

export default withRouter(InActiveUserLayout);

