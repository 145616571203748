/* /src/components/auth/logout.jsx */

import React from "react";
import { AuthConsumer } from "./authProvider";

export const Logout = () => (
    <AuthConsumer>
        {({ logout }) => {
            //logout_done();
            window.location.href =process.env.REACT_APP_GLOBAL_AUTHORIZATION_CODE;
            return <se-loading loading="true"></se-loading>;;
        }}
    </AuthConsumer>
);