import React from 'react'
import * as atlas from 'azure-maps-control';
import _, { result } from 'lodash';
import { string } from 'prop-types';
const AzureMapControls = (props) => {
   const [infoData, setInfoData] = React.useState(props.infoData)
   let site = props.info.filter((i) => i.PropName === "City")[0]['PropValue']
   let lalo = props.locationData.address !== undefined ? props.locationData.position.split(','):[0,0]
   const [latitude, setLatitude] = React.useState(lalo[0])
   const [longitude, setLongitude] = React.useState(lalo[1])
   const [locationData, setLocationData] = React.useState()
   let mapKey = props.mapKey;
   let myMap = null, selectedShape;

   //Define an HTML template for a custom popup content laypout.
   let popupTemplate = '<div class="customInfobox"><div class="name">{name}</div></div>';
   let popup = null;
   const initiateMap = (disabledClick) => {
      if (latitude !== 0 && longitude !== 0) {
         myMap = new atlas.Map('agroMapContainerControls', {
            language: 'en-US',
            authOptions: {
               authType: 'subscriptionKey',
               subscriptionKey: mapKey
            },
            renderWorldCopies: false,
            showLogo: false,
            color: 'DodgerBlue',
            text: '10',
            view: 'Auto',
            // position: [0, 0],
            // center: [longitude, latitude],
            zoom: 0
         });

         //Wait until the map resources are ready.
         myMap.events.add('ready', function () {
            myMap.resize();
            var zoomControl = new atlas.control.ZoomControl();
            /* Add the zoom control to the map*/
            myMap.controls.add(zoomControl, {
               position: "top-right"
            });
            let newPoints = []
            var pin = new atlas.data.Feature(new atlas.data.Point([longitude, latitude]), {
               title: 'Times Square'
            });
            var dataSource = new atlas.source.DataSource();
            myMap.sources.add(dataSource);
            dataSource.add(pin);

            //map hover
            //Create a layer to render point data.
            let symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
               iconOptions: {
                  ignorePlacement: true, //To skip label collision detection for better performance.

                  allowOverlap: true    //To ensure smooth rendering when dragging, allow symbol to overlap all other symbols on the map.
               }
            });
            //Add the polygon and line the symbol layer to the map.
            myMap.layers.add(symbolLayer);
            //Create a popup but leave it closed so we can update it and display it later.

            popup = new atlas.Popup({
               pixelOffset: [0, -18],
               closeButton: false
            });

            let ne=props.locationData.address.boundingBox.northEast.split(',')
            let sw=props.locationData.address.boundingBox.southWest.split(',')
            //Zoom the map into the selected location.
            myMap.setCamera({
               bounds: [sw[1],sw[0],ne[1],ne[0]],
               padding: 30
            });

            myMap.events.add('mouseover', symbolLayer, function (e) {
               if (e.shapes && e.shapes.length > 0) {
                  let content = null
                  let coordinate = null
                  let properties = {}
                  properties.name = "Site Name :- " + site //+ JSON.parse(e.shapes[0].properties._azureMapsShapeId).siteName
                  content = popupTemplate.replace(/{name}/g, properties.name)
                  coordinate = e.position
                  popup.setOptions({
                     //Update the content of the popup.
                     content: content,
                     //Update the popup's position with the symbol's coordinate.
                     position: coordinate

                  });
                  //Open the popup.
                  popup.open(myMap);
               }
            })
            myMap.events.add('mouseleave', symbolLayer, function () {
               popup.close();
            });

            // code added by kalyan
            myMap.events.add('mousedown', symbolLayer, function (e) {
               if (disabledClick === false) {
                  if (e.shapes && e.shapes.length > 0) {
                     //Capture the selected shape.
                     selectedShape = e.shapes[0];

                     //Lock the maps ability to pan so that we can drag the symbol.
                     myMap.setUserInteraction({
                        dragPanInteraction: false
                     });
                  }
               }
            });
            myMap.events.add('mousemove', function (e) {
               //Update the position of the selected shape.
               if (selectedShape) {
                  selectedShape.setCoordinates(e.position);
                  props.onClick(e.position, mapKey)
               }
            });

            myMap.events.add('mouseup', function (e) {
               //Stop tracking the selected shape.
               selectedShape = null;
               myMap.setUserInteraction({
                  dragPanInteraction: true
               });
            });

            myMap.events.add('click', function (e) {
               if (disabledClick === false) {
                  if (e.position && e.position.length > 0) {
                     props.onClick(e.position, mapKey)

                  }
               }

            });
            myMap.layers.add(new atlas.layer.SymbolLayer(dataSource, null));
         });
         //map view end
      }
      else {
      myMap = new atlas.Map('agroMapContainerControls', {
         language: 'en-US',
         authOptions: {
            authType: 'subscriptionKey',
            subscriptionKey: mapKey
         },
         renderWorldCopies: false,
         showLogo: false,
         color: 'DodgerBlue',
         text: '10',
         view: 'Auto',
         // zoom: 0
      });

      //Wait until the map resources are ready.
      myMap.events.add('ready', function () {
         myMap.resize();
         var zoomControl = new atlas.control.ZoomControl();
         /* Add the zoom control to the map*/
         myMap.controls.add(zoomControl, {
            position: "top-right"
         });
         var dataSource = new atlas.source.DataSource();
         myMap.sources.add(dataSource);
         
      });
      // map view end
      }

   }

   React.useEffect(() => {
      // setTimeout(() => {
         initiateMap(props.isDisabled)
      // }, 500);
   }, [props])


   return <div id="agroMapContainerControls" className={props.classStyle}></div>

}

export default AzureMapControls