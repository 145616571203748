import React from "react";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import "./chartjs.css";
import { Doughnut } from 'react-chartjs-2'
const DashboardImpactMetrics = (props) => {
    const [impactmetrics, setImpactmetrics] = React.useState({})
    const [genderDiversity, setGenderDiversity] = React.useState({})
    const navigateToDetailsPage = () => {
        let { history } = props;
        history.push({
            pathname: '/impactMetricsDetails',
            widgetType: "Impact Metrics"
        });
    }

    const getBorder = () => {
        let border = "border-bottom-overview"
        if (impactmetrics.performance === "Above Target") {
            border = "border-bottom-overview bordergreenColor"
        } else if (impactmetrics.performance === "Equals Target") {
            border = "border-bottom-overview borderColor"
        } else if (impactmetrics.performance === "Bellow Target") {
            border = "border-bottom-overview borderredColor"
        }
        return border
        // return impactmetrics.performance === "Above Target" ? "border-bottom-overview bordergreenColor" :
        //     impactmetrics.performance === "Equals Target" ? "border-bottom-overview borderColor" :
        //         "border-bottom-overview borderredColor"
    }
    const renderGraph = () => {
        var dataValue = {

            labels: [],
            datasets: [
                {
                    label: null,
                    backgroundColor: [],
                    borderColor: [],
                    hoverBackgroundColor: [],
                    hoverBorderColor: [],
                    data: []
                }]
        }

        let options = {
            tooltips: {
                enabled: true
            },
            cutoutPercentage: 70,
            legend: {
                display: false,
            }
        }

        let val = sessionStorage.getItem("customerType") === "Agro"? impactmetrics.widgetData.find(ele => ele.propName === "Total Entrepreneurs") : impactmetrics.widgetData.find(ele => ele.propName === "Total Farmers Benefitted");
        
        let plugins = [{
            beforeDraw: function (chart) {
                var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                ctx.restore();
                var fontSize = (height / 60).toFixed(2);
                ctx.font = fontSize + "em Nunito";
                ctx.textBaseline = "top";
                var text =  val.propValue? val.propValue : 0,
                    textX = Math.round((width - ctx.measureText(text).width) / 2),
                    textY = height / 2.5;
                ctx.fillText(text, textX, textY);
                ctx.save();
            }
        }];
        var _men=impactmetrics.widgetData.find( ele => ele.propName === "Men"); 
        var _women=impactmetrics.widgetData.find(ele => ele.propName === "Women"); 
        if(_men.propValue==="0" && _women.propValue=="0"){
                dataValue.datasets[0].backgroundColor.push('#E6E6E6')
                dataValue.datasets[0].borderColor.push('#ffffff')
                dataValue.datasets[0].hoverBackgroundColor.push('#E6E6E6')
                dataValue.datasets[0].hoverBorderColor.push('#E6E6E6')
                dataValue.datasets[0].data.push(1)
                options.tooltips.enabled = false
        }
        else{
            _.map(impactmetrics.widgetData, ele => {
                if (ele.propName === "Men" || ele.propName === "Women") {
                    dataValue.labels.push(ele.propName)
                    dataValue.datasets[0].backgroundColor.push(ele.propSubTitle)
                    dataValue.datasets[0].borderColor.push('#E6E6E6')
                    dataValue.datasets[0].hoverBackgroundColor.push(ele.propSubTitle)
                    dataValue.datasets[0].hoverBorderColor.push(ele.propSubTitle)
                    dataValue.datasets[0].data.push(ele.propValue)
                }
            })
        }
       
        
        // if(dataValue.datasets[0].data.reduce((a, b) => a + b, 0)==0) {
        //         dataValue.datasets[0].backgroundColor.push('#E6E6E6')
        //         dataValue.datasets[0].borderColor.push('#E6E6E6')
        //         dataValue.datasets[0].data.push(1)
        //         options.tooltips.enabled = false
        //     }
        return <Doughnut id="chartjs-genderdiversity" data={dataValue} plugins={plugins} options={options} height="80" width="142" />
    }
    const renderList = _.map(impactmetrics.widgetData, (ele, index) => {

        return ele.propName === "Men" || ele.propName === "Women" ?
            null
            : ele.propName === "Gender Diversity" ?
                <se-block-content key={index} class="blockheight" style={{ padding: '0px' }}>
                    <div className="row align-items-center">
                        <div className="col-md-2">
                            <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{ele.propIcon}</se-icon>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-0">
                            <div className="text-align font-size-12 textColor">{ele.propName} {ele.units ? <span>({ele.units})</span> : null} </div>
                            <div className="text-align font-size-12 textColor" style={{ paddingTop: '5px' }}>
                                <div className="row col-md-12 pl-0">
                                    {sessionStorage.getItem("customerType") === "SWP" ?
                                        <div className="col-md-6 text-align font-size-12 textColor" style={{ textAlign: 'center' }}><span style={{ lineHeight: '25px' }}>(Farmers)</span><br /></div>
                                        : <div className="col-md-6 text-align font-size-12 textColor" style={{ textAlign: 'center' }}><span style={{ lineHeight: '25px' }}>(Entrepreneurs)</span><br /></div>
                                    }
                                </div>
                                {/* <div className="row col-md-12 pl-0">
                                <div className="col-md-6 text-align font-size-12 textColor" style={{ textAlign: 'center' }}><span style={{height:'10px',width:'10px',backgroundColor:genderDiversity.men}}>&nbsp;&nbsp;&nbsp;</span><span style={{lineHeight: '25px'}}>&nbsp;Men</span><br/></div>
                                <div className="col-md-6 text-align font-size-12 textColor" style={{ textAlign: 'center' }}><span style={{height:'10px',width:'10px',backgroundColor:genderDiversity.women}}>&nbsp;&nbsp;&nbsp;</span><span style={{lineHeight: '25px'}}>&nbsp;Women</span><br/></div>
                            </div> */}
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-6 col-md-6 col-lg-6 col-xl-6 pr-0 text-right">
                            {renderGraph()}
                            {/* <span className="downarrowposition downarrow"> </span>
                            <div className="row col-md-12 pl-4 pr-0">
                                <div className="col-md-6 text-align font-size-12 textColor" style={{ textAlign: 'center' }}><span style={{lineHeight: '25px'}}>Women</span><br/>  <span className="font-size-lg">{genderDiversity.women}</span></div>
                                <div className="col-md-6 text-align font-size-12 textColor" style={{ textAlign: 'center' }}><span style={{lineHeight: '25px'}}>Men</span><br/>  <span className="font-size-lg">{genderDiversity.men}</span></div>
                                
                            </div> */}

                        </div>
                    </div>
                </se-block-content>
                : ele.propName === "Direct Beneficiaries" || ele.propName === "Indirect Beneficiaries" ?
                    <se-block-content key={index} class="blockheight" style={{ padding: '0px' }}>
                        <div className="row align-items-center">

                            <div className="col-xs-8 col-sm-8 col-md-7 col-lg-7 col-xl-7 pr-0">
                                <div className="text-align font-size-12 textColor">{ele.propName} {ele.units ? <span>({ele.units})</span> : null} </div>
                                <div className="row text-align font-size-10 textColor">
                                    <div className="col-md-3">
                                        <se-icon size="small" color="standard" style={{ fontSize: "25px" }}>{ele.propIcon}</se-icon>
                                    </div>
                                    <div style={{ paddingTop: '5px' }}>
                                        {ele.propSubTitle}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-3 pr-0 text-right">
                                <span className="font-size-lg" style={{ color: '#CCCCCC' }}>{ele.propValue}</span>
                            </div>
                        </div>
                    </se-block-content>

                    : ele.propIcon === null ?
                        <div className="row align-items-center" style={{ margin: '0' }}>
                            <div className="col-md-2"></div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 pr-0">
                                <div className="text-align font-size-12 textColor" style={{ paddingLeft: '10px' }}>{ele.propName} {ele.units ? <span>({ele.units})</span> : null} </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-3 pr-0 text-right">
                                <span className="font-size-lg" style={{ color: '#CCCCCC' }}>{ele.propValue}</span>
                            </div>
                        </div>
                        : ele.propValue === "" ?
                            <se-block-content key={index} class="blockheight gdoverview">
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{ele.propIcon}</se-icon>
                                    </div>
                                    <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 pr-0" style={{ marginTop: '-20px' }}>
                                        <div className="text-align font-size-12 textColor">{ele.propName} {ele.units ? <span>({ele.units})</span> : null} </div>
                                    </div>

                                </div>

                            </se-block-content>
                            : ele.propName === "Total Farmers Benefited" || ele.propName === "Total Entrepreneurs" || ele.propName === "Total Farmers Benefitted" ? <se-block-content key={index} class="blockheight" style={{ padding: '15px 0 0 0' }}>
                                <div className="row align-items-center">
                                    <div className="col-md-2">
                                        <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{ele.propIcon}</se-icon>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 pr-0">
                                        <div className="text-align font-size-12 textColor">{ele.propName} {ele.units ? <span>({ele.units})</span> : null} </div>
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-0 text-center font-size-lg">
                                        {ele.propValue}
                                    </div>
                                </div>
                            </se-block-content>
                                : <se-block-content key={index} class="blockheight" style={{ padding: '0px' }}>
                                    <div className="row align-items-center">
                                        <div className="col-md-2">
                                            <se-icon size="large" color="standard" style={{ fontSize: "40px" }}>{ele.propIcon}</se-icon>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-5 pr-0">
                                            <div className="text-align font-size-12 textColor">{ele.propName} {ele.units ? <span>({ele.units})</span> : null} </div>
                                        </div>
                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 pr-0 text-center font-size-lg">
                                            {ele.propValue}
                                        </div>
                                    </div>
                                </se-block-content>
    })
    React.useEffect(() => {
        if (props.impactmetrics) {
            setImpactmetrics(props.impactmetrics)
            
            if (props.impactmetrics.widgetData !== undefined) {
                let men = props.impactmetrics.widgetData.find(obj => obj.propName === "Men")
                let women = props.impactmetrics.widgetData.find(obj => obj.propName === "Women")
                let ob = {}
                ob.men = men==undefined?'':men.propSubTitle;
                ob.women = women==undefined?'':women.propSubTitle;
                setGenderDiversity(ob);
                
            }


        }
    }, [props.impactmetrics])

    return <se-block margin="none" class="font-icon" style={{ height: "54vh", borderBottom: "1px solid #00000029", overflow: "hidden" }}>
        <div class={getBorder()}>
            <div class="rows align-items-center">
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8"><span class="font-size-16">{impactmetrics.widgetType}</span></div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 textColor font-size-12 my-auto">Lifetime</div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 my-auto">
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <se-icon onClick={() => navigateToDetailsPage()} class="pointer valign-super" option="" color="" size="nano">presentation_mode</se-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {renderList}
    </se-block>
}
export default withRouter(DashboardImpactMetrics)