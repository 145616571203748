import React, { Component } from "react";
import * as atlas from 'azure-maps-control';
import _, { result } from 'lodash';
import img_active from '../images/mechine_running.png'
import img_incative from '../images/mechine_inactive.png'
const AzureMaps = (props) => {
    const lat = {}, log = {};
    for (let lo of props.LocationObject) {
        let a = lo.geometry.coordinates[0], b = lo.geometry.coordinates[1]
        lat[a] = (lat[a] || 0) + 1;
        log[b] = (log[b] || 0) + 1;
    }
    // const [mapData, setMapData] = React.useState({})
    const a = Number(Object.keys(lat).reduce((a, b) => lat[a] > lat[b] ? a : b)), b = Number(Object.keys(log).reduce((a, b) => log[a] > log[b] ? a : b))
    
    const [latitude, setLatitude] = React.useState(props.latitude ? a : 77)
    const [longitude, setLongitude] = React.useState(props.longitude ? b : 12)
    
    let mapKey = "Wh2PoXcr2n_lms3BUkbpH_0zynlRn4mvUUZ8O2_xPe0"
    let features = []
    const GetMap = (mapData) => {
        var map=null,dataSource=null;
         map = new atlas.Map('agroMapContainer', {
            authOptions: {
                authType: 'subscriptionKey',
                subscriptionKey: mapKey
            },
            renderWorldCopies: false,
            showLogo: false,
            color: 'DodgerBlue',
            text: '10',
            view: 'Auto',
            language: 'en-US',
            // position: [0, 0],
            center: [80, 19],
            zoom: 3
        });
        
        var  popup
        // var earthquakeFeed = "https://a2elivelihood.blob.core.windows.net/a2eagroreports/SamplePoiDataSet.json";
        //Wait until the map resources are ready.
        map.events.add('ready', function () {
            
            var iconPromises = [
                // map.imageSprite.add('motor_active_icon', 'https://a2elivelihood.blob.core.windows.net/a2eagroreports/mechine_running.png'),
                // map.imageSprite.add('motor_inactive_icon', 'https://a2elivelihood.blob.core.windows.net/a2eagroreports/mechine_inactive.png'),
                map.imageSprite.add('motor_active_icon', img_active),
                map.imageSprite.add('motor_inactive_icon', img_incative),
            ];
            
            Promise.all(iconPromises).then(function () {
                /* Construct a zoom control*/
                map.resize();
                var zoomControl = new atlas.control.ZoomControl();
                /* Add the zoom control to the map*/
                map.controls.add(zoomControl, {
                    position: "top-right"
                });

                dataSource = new atlas.source.DataSource(null, {
                    //Tell the data source to cluster point data.
                    cluster: true
                });
                map.sources.add(dataSource);
                //Add a layer for rendering point data as symbols.
                var cluster_details = new atlas.layer.SymbolLayer(dataSource, null, {
                    // iconOptions: {
                    //     image: 'default-icon'
                    // },
                    // textOptions: {
                    //     textField: ['get', 'point_count_abbreviated'],
                    //     offset: [0, 0.4]
                    // }
                })
                // var clusterBubbleLayer = new atlas.layer.BubbleLayer(dataSource, null, {
                //     radius: 20,
                //     color: '#0F81B3',
                //     strokeWidth: 0,
                //     filter: ['has', 'point_count'] //Only rendered data points which have a point_count property, which clusters do.
                // });
                var details_layer = new atlas.layer.SymbolLayer(dataSource, null, {
                    iconOptions: {
                        image: [
                            'match',
                            ['get', 'EntityType'],
                            1, 'motor_active_icon',
                            0, 'motor_inactive_icon',
                            'marker-blue'
                        ],
                        size: 0.4
                    },
                    textOptions: {
                        textField: ['get', 'location'],    //Use the title property to populate the text for the symbols.
                        size: 14,
                        // font: ['SegoeUi-Bold'],
                        color: 'gray',
                        offset: [0, 1.5]
                    },
                    filter: ['!', ['has', 'point_count']] //Filter out clustered points from this layer.
                });
                map.layers.add([cluster_details, details_layer]);
                map.events.add('click', details_layer, function (e) {
                    
                    if (e.shapes && e.shapes.length > 0) {
                        var properties = e.shapes[0].data.properties;
                        props.onClick(properties.id, properties.location);
                    }
                });
                //Retrieve a GeoJSON data set and add it to the data source.
                // dataSource.importDataFromUrl(earthquakeFeed);
                dataSource.add(props.LocationObject);

                if(mapData.address!== undefined){
                    // console.log('map',mapData,Object.keys(mapData).length);
                    let ne = mapData.address.boundingBox.northEast.split(',')
                    let sw = mapData.address.boundingBox.southWest.split(',')
                    //Zoom the map into the selected location.
                    
                    let ll = mapData.position.split(',')
                    let latlog = []
                    latlog.push(Number(ll[1]))
                    latlog.push(Number(ll[0]))
                    // console.log(latlog,map.getCamera().zoom)
                    map.setCamera({
                        center:latlog,
                        zoom:15
                    }
                    // ,{
                    //     bounds: [sw[1], sw[0], ne[1], ne[0]],
                    // }
                    );
                }
                else{
                    // console.log('map end',Object.keys(props.MapZoomData).length)
                }

                
                
                
            });
            
            
        });
        
    }
    const isEmpty=(obj)=> {
        return Object.keys(obj).length === 0;
    }
    React.useEffect(() => {
        GetMap(props.MapZoomData)
        // setMapData(props.MapZoomData)
    }, [])
    React.useEffect(() => {
        GetMap(props.MapZoomData)
        
    }, [props])
    return <div>
        <div id="agroMapContainer"></div>
        </div>
}
export default AzureMaps;

