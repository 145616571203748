import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import loginBackground from '../images/operations.jpg';
import './styles.css';
class Settings extends Component {

    navigatetoProfile() {
        let { history } = this.props;
        history.push({
            pathname: '/myprofile'
        });
    }

    navigatetoCreateNewUser() {
        let { history } = this.props;
        // console.log("props create",this.props)
        history.push({
            pathname: '/createnewuser'
        });
    }

    render() {
        return (
            <se-container option="centered">
          <se-block className="container mt-3" option="fill">
          <p className="align_Label" id="input-form" label="Profile">PROFILE</p>
    <se-list option="nav">
    <se-list-item item="My Profile" onClick={this.navigatetoProfile.bind(this)}
 ></se-list-item>
    </se-list>
    <p className="align_Label" id="input-form" label="Profile">Preferences</p>
    <se-list option="nav">
      <se-list-item item="Notifications"></se-list-item>
      <se-list-item item="Language"></se-list-item>
      <se-list-item item="Report Customization"></se-list-item>
      <se-list-item item="Show Help"></se-list-item>
      </se-list>
      <p className="align_Label" id="input-form" label="Profile">Users</p>
    <se-list option="nav">
    <se-list-item item="Create new user" onClick={this.navigatetoCreateNewUser.bind(this)}></se-list-item>
    </se-list>
    <p className="align_Label" id="input-form" label="Profile">Others</p>
    <se-list option="nav">
    <se-list-item item="Terms and Conditions" ></se-list-item>
    <se-list-item item="Support"></se-list-item>
    </se-list>
    
   
  </se-block>
            </se-container>
        );
    }
}

export default withRouter(Settings);