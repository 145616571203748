import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dashboardwidget from '../components/dashboardwidget';
import Dashboardwidgetbarchart from '../components/dashboardwidgetbarchart';
import Dashboardwidgetinstallaton from '../components/dashboardwidgetinstallation';
import DashboardImpactMetrics from '../components/dashboardImpactMetrics';
import DashboardAgriProduced from '../components/dashboardagriproduced';
import DashboardRevenue from '../components/dashboardrevenue';
import DashboarNotification from '../components/dashboardnotification'
import CustomerLocations from '../components/customerLocations'
import Partner from '../components/partner';
import Auth from '../api/ApiSession';
import moment from 'moment';
const authCall = new Auth();
const OverView = (props) => {
    const [isLoading, setIsLoading] = React.useState(true)
    const [customerList, setCustomerList] = React.useState([])
    const [totalCustomers, setTotalCustomers] = React.useState(0)
    const [agriproduced, setAgriproduced] = React.useState({})
    const [impactmetrics, setImpactmetrics] = React.useState({})
    const [capacityUtilization, setCapacityUtilization] = React.useState([])
    const [revenue, setRevenue] = React.useState([])
    const [environment, setEnvironment] = React.useState([])
    const [installation, setInstallation] = React.useState([])
    const [installation1, setInstallation1] = React.useState([])
    const [RealTime, setRealTime] = React.useState([])
    const [notification, setNotification] = React.useState({})
    const timerRef = React.useRef()
    const onToggle = (customerTypeCallback) => {
        props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
        setIsLoading(true)
        setAgriproduced({})
        sessionStorage.setItem("customerId", 0)
        sessionStorage.setItem("siteName", "")
        sessionStorage.setItem("customerType", customerTypeCallback);
        fetchDashboardData();
        fetchDashboardData1();
        fetchDashboardNotification();
        fetchCustomerDetails();
    }
    const selectedUserId = (customerId, siteName) => {
        props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
        setIsLoading(true)
        sessionStorage.setItem("customerId", parseInt(customerId));
        if (siteName !== "All Sites") {
            sessionStorage.setItem("siteName", siteName);
        } else {
            sessionStorage.setItem("siteName", "");
        }
        fetchDashboardData();
        fetchDashboardData1();
        fetchDashboardNotification();
    }
    const fetchDashboardData = () => {
        let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro"
        var jsonBody = {
            userId: parseInt(sessionStorage.getItem("userId")),
            siteName: sessionStorage.getItem("siteName"),
            customerId: parseInt(sessionStorage.getItem("customerId")),
            customerType: customerType
        };
        authCall.postSettingsWidget(jsonBody).then(json => {
            setImpactmetrics(json.settingsWidget[0]);
            setInstallation(json.settingsWidget[1]);
            setInstallation1(json.settingsWidget[2]);
        }).catch(error => {
            console.log(error);
        });

    }
    const fetchDashboardData1 = () => {
        let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro"
        var jsonBody = {
            userId: parseInt(sessionStorage.getItem("userId")),
            siteName: sessionStorage.getItem("siteName"),
            customerId: parseInt(sessionStorage.getItem("customerId")),
            customerType: customerType
        };
        authCall.postCalculateWidget(jsonBody).then(json => {
            setAgriproduced(json.OverWidgetDetails[0])
            setCapacityUtilization(json.OverWidgetDetails[1])
            setRevenue(json.OverWidgetDetails[2])
            setEnvironment(json.OverWidgetDetails[3])
            setRealTime(json.OverWidgetDetails[4])
            setIsLoading(false)
        }).catch(error => {
            console.log(error);
        });
    }
    const fetchDashboardNotification = () => {
        let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro"
        var jsonBody = {
            userId: parseInt(sessionStorage.getItem("userId")),
            siteName: sessionStorage.getItem("siteName"),
            customerId: parseInt(sessionStorage.getItem("customerId")),
            notificationDuration: 1,
            customerType: customerType,
        };
        authCall.postNotificationWidget(sessionStorage.getItem("userId"), jsonBody).then(json => {
            setNotification(json.ResponseData);
        }).catch(error => {
            console.log(error);
        });
    }
    const fetchCustomerDetails = () => {
        let customerType = (sessionStorage.getItem("customerType") === null ? 'Agro' : sessionStorage.getItem("customerType"))
        authCall.getAgroCustomersList(sessionStorage.getItem("userId"), customerType).then(entries => {
            entries=entries.filter((item) => item.installedDate !== null);
            
            if(entries.length===0){
                let { history } = props;
                history.push({
                pathname: '/mycustomers'
                });
            }
            setCustomerList(entries)
            setTotalCustomers(entries.length)
            let _site=entries.filter(e=>e.customerId===parseInt(sessionStorage.getItem("customerId")))
            if(_site.length){
                sessionStorage.setItem("siteName", _site[0].location);
            }
            // console.log(entries,_site)
        }).catch(error => {
            console.log(error);
        });
    }
    React.useEffect(() => {
        
        props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
        fetchDashboardData();
        fetchDashboardData1();
        fetchDashboardNotification();
        fetchCustomerDetails();
    }, [])
    React.useEffect(() => {
      
        timerRef.current = setInterval(() => {
            props.onChange(moment().format('DD/MM/YYYY h:mm:ss a'))
            fetchDashboardData();
            fetchDashboardData1();
            fetchDashboardNotification();
            fetchCustomerDetails();
        }, 900000);

        return () => {
            clearInterval(timerRef.current)
        }
    }, [])

    return <se-container display="flex" padding="medium">
        {isLoading ? <div class="align-items-center"><se-loading loading="true"></se-loading></div> : null}
        <se-block margin="medium" class="col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden" color="standard">
            <CustomerLocations customerList={customerList} totalCustomers={totalCustomers}
                onChange={onToggle}
                onClick={selectedUserId} />
            <DashboarNotification className="pt-3" notification={notification} />
        </se-block>
        <se-block margin="medium" class="col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden" color="standard">
            <DashboardImpactMetrics impactmetrics={impactmetrics} customerType={sessionStorage.getItem("customerType")} />
            <DashboardAgriProduced agriproduced={agriproduced} />
        </se-block>
        <se-block margin="medium" class="col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden" color="standard">
            <se-block margin="none" class="font-icon overflow-hidden" style={{ height:"54vh", borderBottom: "1px solid #00000029"}}>
                <Dashboardwidgetbarchart capacityUtilization={capacityUtilization} />
                <DashboardRevenue revenue={revenue} />
            </se-block>
            <Dashboardwidget environment={environment} />
        </se-block>
        <se-block margin="medium" class="col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden" color="standard">
            <Dashboardwidgetinstallaton installation={installation} installation1={installation1} />
            <Partner realTime={RealTime} />
        </se-block>
    </se-container>

}

export default withRouter(OverView);